const CONFIG = {
  companyName: 'Dynamic KSA',
  phone: '+966 569422225',
  address: '7277 طـريق الملك فـهد - العليا ،بـرج الفيصلية الرياض',
  email: 'info@dynamicksa.com',
  twitterUrl: 'https://twitter.com/Dynamiceg1',
  facebookUrl: 'https://www.facebook.com/profile.php?id=61554149536363',
  linkedInUrl: 'https://www.linkedin.com/company/5363220/admin/feed/posts/',
  egyptAddress:
    '34 مكرم عبيد، المنطقة السادسة، مدينة نصر، محافظة القاهرة 11765',
  egyptPhone: '+201203333843',
  egyptEmail: 'info@dynamiceg.com',
};

const ARABIC_COUNTRY_CODES = [
  'dz', // Algeria
  'bh', // Bahrain
  'eg', // Egypt
  'iq', // Iraq
  'jo', // Jordan
  'kw', // Kuwait
  'om', // Oman
  'qa', // Qatar
  'sa', // Saudi Arabia
  'ae', // United Arab Emirates
  'ye', // Yemen
];

export default CONFIG;
export { ARABIC_COUNTRY_CODES };
