import { PhoneNumberUtil } from 'google-libphonenumber';

function isPhoneValid(phone) {
  const phoneUtil = PhoneNumberUtil.getInstance();

  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
}

function scrollToTop() {
  return window.scrollTo({ top: 0, behavior: 'smooth' });
}

// phone number: remove plus and space
function initPhoneNumber(num) {
  return num.replace(/\s/g, '').replace('+', '');
}

function toArabicDigits(number) {
  const id = '٠١٢٣٤٥٦٧٨٩';
  return number.toString().replace(/\d/g, (w) => id[+w]);
}

export { scrollToTop, initPhoneNumber, toArabicDigits, isPhoneValid };
