import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { SOLUTION_OPTIONS } from '../../core/data';
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from 'react-international-phone';
import { isPhoneValid } from '../../utils';
import CONFIG, { ARABIC_COUNTRY_CODES } from '../../core/config';
import { useHistory } from 'react-router-dom';

function ContactForm() {
  const { t } = useTranslation();
  const captchaRef = useRef(null);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [formInfo, setFormInfo] = useState({
    name: '',
    email: '',
    solution: '',
    job: '',
    mobile: '',
    company: '',
  });

  const onInputChange = (evt) => {
    setFormInfo({
      ...formInfo,
      [evt.target.name]: evt.target.value,
    });
  };

  const onFormSubmit = async (evt) => {
    evt.preventDefault();

    const token = captchaRef.current?.getValue();

    if (!token) {
      toast.error(t('please-verify-that-you-are-not-a-bot'));
      return;
    }

    if (!isPhoneValid(formInfo.mobile)) {
      toast.error(t('please-enter-a-valid-phone-number'));
      return;
    }

    setIsLoading(true);

    const templateParams = {
      from_name: formInfo.name,
      to_name: 'Dynamic KSA',
      mobile: formInfo.mobile,
      email: formInfo.email,
      solution: formInfo.solution,
      job: formInfo.job,
      company: formInfo.company,
    };

    await emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID ?? '',
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID ?? '',
        templateParams,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          toast.success(t('thank-you-we-received-your-message'));

          setFormInfo({
            name: '',
            email: '',
            job: '',
            solution: '',
            mobile: '',
            company: '',
          });

          captchaRef.current?.reset();

          history.replace(
            '/success-6cb668ef1ac55754e8472e1f3781212683a21e28920956300cbf974a9c4432be'
          );
        },
        (err) => {
          toast.error(t('error-please-try-again'));
          console.log(err);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ARABIC_COUNTRY_CODES.includes(iso2);
  });

  return (
    <div className='container'>
      <ToastContainer position='bottom-center' rtl />

      <div className='row g-5 align-items-center'>
        <div className='col-lg-5'>
          <div className='text-center'>
            <h2>هل تحتاج الى مكالمة سريعة</h2>
            <a href={`tel:${CONFIG.phone}`} className='contact-phone'>
              اتصل بنا علي <span dir='ltr'>{CONFIG.phone}</span>
            </a>

            <div className='text-aurora fw-bold mt-3'>
              متاح من الساعة 8 صباحا حتى 5 مساءًا خلال ايام العمل
            </div>
          </div>
        </div>
        <div className='col-lg-7'>
          <div className='form-wrapper'>
            <div className='form-title2 mb-5'>
              <h3>اطلب عرض تجريبي مجاني</h3>
              <p className='para'>
                شركاء ديناميك من كل القطاعات والشركات كن احدهم الان
              </p>
            </div>

            <form onSubmit={onFormSubmit}>
              <div className='row'>
                <div className='col-xl-6 col-lg-12 col-md-6'>
                  <div className='form-inner'>
                    <input
                      type='text'
                      placeholder={t('full-name')}
                      value={formInfo.name}
                      name='name'
                      required
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className='col-xl-6 col-lg-12 col-md-6'>
                  <div className='form-inner'>
                    <input
                      type='text'
                      placeholder={t('your-company')}
                      value={formInfo.company}
                      name='company'
                      required
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className='col-xl-6 col-lg-12 col-md-6'>
                  <div className='form-inner'>
                    <input
                      type='email'
                      placeholder={t('email')}
                      value={formInfo.email}
                      onChange={onInputChange}
                      name='email'
                      required
                    />
                  </div>
                </div>

                <div className='col-xl-6 col-lg-12 col-md-6'>
                  <div className='form-inner'>
                    <input
                      type='text'
                      placeholder={t('job-title')}
                      value={formInfo.job}
                      name='job'
                      required
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className='col-xl-6 col-lg-12 col-md-6'>
                  <div className='form-inner'>
                    <PhoneInput
                      defaultCountry='sa'
                      required
                      countries={countries}
                      value={formInfo.mobile}
                      name='mobile'
                      onChange={(value) =>
                        setFormInfo({ ...formInfo, mobile: value })
                      }
                      placeholder={t('your-phone')}
                    />
                  </div>
                </div>

                {/* <div className='col-xl-6 col-lg-12 col-md-6'>
                  <div className='form-inner'>
                    <Select
                      isRtl
                      name='solution'
                      required
                      value={
                        formInfo.solution
                          ? SOLUTION_OPTIONS.find(
                              (item) => item.value === formInfo.solution
                            )
                          : null
                      }
                      isSearchable={false}
                      className='basic-single'
                      placeholder={t('subject')}
                      classNamePrefix='select-contact'
                      options={SOLUTION_OPTIONS}
                      onChange={(item) =>
                        setFormInfo({
                          ...formInfo,
                          solution: item?.value ?? '',
                        })
                      }
                    />
                  </div>
                </div> */}

                <div className='col-12'>
                  {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      ref={captchaRef}
                    />
                  )}
                </div>

                <div className='col-12'>
                  <button
                    type='submit'
                    disabled={isLoading}
                    className='eg-btn btn--primary btn--md form--btn'
                  >
                    {isLoading ? t('loading') : 'طلب عرض تجريبي مجاني'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
