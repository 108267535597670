import React from 'react';
import CountUp from 'react-countup';
import { STATUS } from '../../core/data';

function AboutUsCounter() {
  return (
    <div className='about-us-counter'>
      <div className='container'>
        <div className='row g-4 d-flex justify-content-center'>
          {STATUS.map((item) => (
            <div className='col-lg-3 col-md-6 col-sm-10 col-10 ' key={item.id}>
              <div
                className='counter-single text-center d-flex flex-row hover-border1 wow fadeInDown'
                data-wow-duration={item.wowDuration}
                data-wow-delay={item.wowDelay}
              >
                <div className='counter-icon'>
                  <img alt='images' width={64} height={64} src={item.image} />
                </div>

                <div className='coundown d-flex flex-column'>
                  <h3>
                    <CountUp end={item.endCount} delay={2} duration={5} />
                  </h3>

                  <p>{item.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AboutUsCounter;
