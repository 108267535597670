import React from 'react';

const SERVICES = [
  {
    id: 1,
    title: 'التدريب والتطبيق',
    image: '/images/services/service-6.png',
  },
  {
    id: 2,
    title: 'خدمات الاستضافه',
    image: '/images/services/service-8.png',
  },
  {
    id: 3,
    title: ' الدعم الفني',
    image: '/images/services/service-2.png',
  },
  {
    id: 4,
    title: 'الربط و التكامل',
    image: '/images/services/service-3.png',
  },
  {
    id: 5,
    title: 'تحليل البيانات',
    image: '/images/services/service-5.png',
  },
  {
    id: 6,
    title: 'الاستشارات',
    image: '/images/services/service-1.png',
  },
  {
    id: 7,
    title: 'تطبيقات الموبايل',
    image: '/images/services/service-7.png',
  },
  {
    id: 8,
    title: 'امن المعلومات',
    image: '/images/services/service-4.png',
  },
];

const WHY_CHOOSE_US = [
  {
    id: 1,
    title: 'خدمات سحابيه',
    image: '/images/why-us/image-2.png',
  },
  {
    id: 2,
    title: 'عملات متعددة (سعر الصرف تلقائيا).',
    image: '/images/why-us/image-4.png',
  },
  {
    id: 3,
    title: 'لدينا خبرة في كل المجالات و الصناعات',
    image: '/images/why-us/image-5.png',
  },
  {
    id: 4,
    title: 'خبرة اكثر من 15 عام في مجال البرمجيات',
    image: '/images/why-us/image-1.png',
  },
  {
    id: 5,
    title: 'خبرة في تقديم خدمات ما بعد البيع اعلى كفاءة .',
    image: '/images/why-us/image-3.png',
  },
  {
    id: 6,
    title: 'واجهة سهلة الاستخدام.',
    image: '/images/why-us/image-6.png',
  },
];

const ERPNEXT_MODULES = [
  {
    id: 1,
    title: 'الحسابات',
    image: '/images/solutions/next-erp/modules/module-7.png',
    content: (
      <>
        <p className='para'>
          موديول الحسابات في نظام ERP يتمثل في الجزء الذي يدير ويتتبع العمليات
          المالية والمحاسبية داخل المؤسسة. يُستخدم هذا الموديول لتنظيم وتتبع
          جميع العمليات المالية، مثل الفواتير الواردة والصادرة، والرواتب،
          والحسابات المستحقة والمدفوعة، والتقارير المالية الشهرية والسنوية.
        </p>

        <p className='para'>تشمل وظائف موديول الحسابات ما يلي:</p>

        <ul className='describe-list'>
          <li>
            <strong>إدارة الحسابات المدينة والدائنة :</strong> <br /> تسجيل
            ومتابعة كل المعاملات المالية الواردة والصادرة.
          </li>
          <li>
            <strong>إدارة الفواتير والدفوعات :</strong> <br /> إنشاء ومتابعة
            الفواتير المستحقة والدفوعات الواردة والمدفوعات.
          </li>
          <li>
            <strong>إدارة الحسابات البنكية :</strong> <br /> تتبع العمليات
            المصرفية، ومطابقة البيانات المصرفية مع سجلات الحسابات.
          </li>
          <li>
            <strong>إدارة الرواتب والمزايا الاجتماعية :</strong> <br /> حساب
            وتتبع مرتبات الموظفين والمزايا الإضافية والضرائب عليها.
          </li>
          <li>
            <strong> تقارير مالية :</strong> <br /> إنشاء تقارير مالية دورية مثل
            قائمة الدخل والميزانية وقوائم التدفقات النقدية وتحليلات مالية أخرى.
          </li>
          <li>
            <strong>إدارة الضرائب والامتثال المالي :</strong> <br /> ضمان التمتع
            بالامتثال القانوني للضرائب والقوانين المالية الأخرى.
          </li>
          <li>
            <strong> إدارة الأصول الثابتة :</strong> <br /> تتبع وإدارة الأصول
            الثابتة للشركة مثل المباني والمعدات.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    title: 'المشتريات',
    image: '/images/solutions/next-erp/modules/module-10.png',
    content: (
      <>
        <p className='para'>
          يهدف إلى تنظيم وتنسيق عمليات الشراء داخل الشركة بشكل متكامل وفعّال.
          يعتبر هذا الموديول أداة حيوية لإدارة سلسلة التوريد، حيث يُسهم في تحسين
          كفاءة العمليات وتقليل التكاليف وزيادة التحكم والشفافية في عمليات
          الشراء.
        </p>

        <p className='para'> من بين الوظائف الرئيسية لموديول المشتريات:</p>

        <ul className='describe-list'>
          <li>طلب المواد </li>
          <li> امر الشراء</li>
          <li> فاتورة الشراء</li>
          <li> طلب عرض سعر </li>
          <li> عرض سعر المورد </li>
          <li> المورد </li>
          <li>مجموعة الموردين </li>
          <li>ضرائب المشتريات </li>
          <li> التقارير الرئيسيه </li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    title: 'المبيعات',
    image: '/images/solutions/next-erp/modules/module-8.png',
    content: (
      <>
        <p className='para'>
          باستخدام موديول المبيعات، تستطيع الشركات تحسين عمليات البيع وزيادة
          إيراداتها، كما يمكن أن يساهم في تعزيز تجربة العملاء وبناء علاقات دائمة
          ومجديّة معهم.
        </p>

        <p className='para'>تشمل وظائف موديول المبيعات ما يلي:</p>

        <ul className='describe-list'>
          <li>
            <strong>إدارة العملاء والعلاقات الشخصية:</strong> <br /> يتيح
            الموديول إدارة بيانات العملاء بشكل شامل، بما في ذلك معلومات الاتصال
            والتاريخ التجاري والمعاملات السابقة، مما يساعد في تحليل احتياجات
            العملاء وتقديم خدمات مخصصة لهم.
          </li>
          <li>
            <strong> إدارة الطلبات والمبيعات :</strong> <br /> يسهل الموديول
            إنشاء ومتابعة الطلبات والمبيعات بشكل فعّال، بما في ذلك توليد العروض
            وإصدار فواتير البيع وتتبع حالة الشحن وتسجيل المبيعات.
          </li>
          <li>
            <strong>تحليل البيانات وتقارير البيع :</strong> <br /> يتيح الموديول
            توليد تقارير مفصلة حول أداء البيع والمبيعات والإيرادات، مما يساعد في
            اتخاذ القرارات الاستراتيجية وتحديد الاتجاهات والفرص لتحسين الأداء
            التجاري.
          </li>
          <li>
            <strong>إدارة الأسعار والتخفيضات :</strong> <br /> يتيح الموديول
            إدارة أسعار المنتجات وتطبيق التخفيضات والعروض الترويجية بشكل مرن،
            مما يساعد في زيادة مبيعات الشركة وجذب المزيد من العملاء.
          </li>
          <li>
            <strong>إدارة المخزون والتوزيع :</strong> <br /> يسهل الموديول تتبع
            مخزون المنتجات وإدارة عمليات التوزيع، بما في ذلك إدارة الطلبات
            الواردة وجدولة الشحن وتتبع التسليمات.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    title: 'المخازن',
    image: '/images/solutions/next-erp/modules/module-9.png',
    content: (
      <>
        <p className='para'>
          من خلال موديول المخزون، تستطيع الشركات تحسين إدارة مخزونها بشكل شامل،
          وبالتالي تحسين كفاءة عملياتها وتقليل التكاليف وتحسين رضا العملاء من
          خلال ضمان توافر المنتجات بشكل دائم وفي الوقت المناسب.
        </p>

        <p className='para'>تشمل وظائف موديول المخزون ما يلي:</p>

        <ul className='describe-list'>
          <li>
            <strong>إدارة المواد والمنتجات:</strong> <br /> يسهل الموديول تتبع
            المواد والمنتجات بشكل دقيق، بما في ذلك تخزين المعلومات المتعلقة
            بالموردين والوحدات والتصنيفات والمواصفات.
          </li>
          <li>
            <strong>إدارة المخزون الدوري والدائم :</strong> <br /> يساعد
            الموديول في متابعة حركة المخزون بشكل مستمر، سواء كان ذلك بشكل دوري
            (مثل الجرد السنوي) أو دائم (بتحديثات فورية).
          </li>
          <li>
            <strong>تحديد مستويات الطلب والإعادة الطلب :</strong> <br /> يمكن
            للموديول تحديد مستويات الطلب المثلى وتنبيه الإدارة بشكل تلقائي عندما
            تنخفض مستويات المخزون إلى مستويات معينة تحتاج إلى إعادة طلب المزيد
            من المواد.
          </li>
          <li>
            <strong>إدارة الشحن والتوزيع :</strong> <br /> يسهل الموديول تتبع
            الشحنات المرسلة والمستلمة وإدارة عمليات التوزيع بشكل متكامل، بما في
            ذلك تخطيط وجدولة الشحن وتسجيل الوصول.
          </li>
          <li>
            <strong>مراقبة التكاليف :</strong> <br /> يمكن للموديول مراقبة
            التكاليف المتعلقة بالمخزون، بما في ذلك تكاليف الشراء وتكاليف التخزين
            والتكاليف العامة، مما يساعد في تحديد التكاليف الإجمالية وتحسين إدارة
            التكاليف.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 5,
    title: 'الاصول الثابتة',
    image: '/images/solutions/next-erp/modules/module-2.png',
    content: (
      <>
        <p className='para'>
          مديول الأصول هو جزء مهم من النظام الكلي، والذي يهدف إلى إدارة جميع
          أنواع الأصول التي تمتلكها المؤسسة، سواء كانت مواد، أو معدات، أو
          ممتلكات ثابتة، مثل الأراضي والمباني. يتمثل هدف هذا الموديل في تتبع
          وإدارة الأصول بطريقة فعالة للحفاظ على القيمة وتحسين الأداء المالي
          للشركة.
        </p>

        <p className='para'>
          ويشمل مديول الأصول ف العديد من الوظائف والميزات التي تشمل على سبيل
          المثال لا الحصر:-
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong> تسجيل الأصول:</strong> <br />
            يمكن لمديول الأصول تسجيل جميع الأصول التي تمتلكها المؤسسة، بما في
            ذلك تفاصيل مثل الرقم التسلسلي، وتاريخ الشراء، والقيمة الحالية.
          </li>
          <li>
            <strong> إدارة الصيانة :</strong> <br /> يوفر المديول أدوات لإدارة
            الصيانة الوقائية والتصليحية للأصول، مما يساعد في الحفاظ على كفاءة
            الأصول وتقليل التكاليف الناجمة عن الإصلاحات الطارئة.
          </li>
          <li>
            <strong> تقييم الأصول :</strong> <br />
            يمكن لمديول الأصول تقديم تقارير دورية حول قيمة الأصول وتقييمها بناءً
            على قيمة السوق أو قيمة الاستبعاد.
          </li>
          <li>
            <strong> تتبع الاستخدام :</strong> <br /> يمكن للمديول تتبع استخدام
            الأصول والتقارير المتعلقة بالاستخدام الفعلي مقارنة بالاستخدام المخطط
            له.
          </li>
          <li>
            <strong> إعداد التقارير المالية :</strong> <br />
            يتيح مديول الأصول إعداد التقارير المالية ذات الصلة بالأصول، مثل
            الإهلاك والقيمة الصافية للأصول.
          </li>
          <li>
            <strong> إدارة دورة حياة الأصول :</strong> <br /> يمكن لمديول الأصول
            إدارة جميع جوانب دورة حياة الأصول، بدءًا من شرائها وانتهاءً
            بإستبعادها أو بيعها.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    title: 'CRM',
    image: '/images/solutions/next-erp/modules/module-3.png',
    content: (
      <>
        <p className='para'>
          تساعدك شاشة إدارة علاقة العملاء على تتبع فرص العمل من العملاء
          المتوقعين والعملاء الفعليين ، وإرسال عروض الأسعار إليهم ، وإصدار أوامر
          مبيعات مؤكدة
        </p>

        <ul className='describe-list'>
          <li> عميل محتمل </li>
          <li> فرصه</li>
          <li> عميل </li>
          <li> التواصل </li>
          <li> مصدر العميل المحتمل </li>
          <li> العقد </li>
          <li>المواعيد </li>
          <li>حملات اعلانيه </li>
        </ul>
      </>
    ),
  },
  {
    id: 7,
    title: 'التصنيع',
    image: '/images/solutions/next-erp/modules/module-1.png',
    content: (
      <>
        <p className='para'>
          يتمثل الهدف الرئيسي لهذا الموديول في تحسين كفاءة عمليات التصنيع وزيادة
          الإنتاجية والجودة وتقليل التكاليف
        </p>

        <p className='para'>تشمل وظائف التصنيع المخزون ما يلي:</p>

        <ul className='describe-list mb-5'>
          <li>
            <strong>تخطيط الموارد:</strong> <br />
            يتيح الموديول تخطيط الإنتاج بناءً على الطلبات الواردة والموارد
            المتاحة، بما في ذلك المواد الخام والقدرات الإنتاجية والعمالة.
          </li>
          <li>
            <strong> إدارة جدولة الإنتاج :</strong> <br /> يسهل الموديول جدولة
            عمليات الإنتاج بشكل فعّال، مما يساعد في تحديد الجداول الزمنية وتخصيص
            الموارد بشكل مثلى.
          </li>
          <li>
            <strong>متابعة التقدم :</strong> <br />
            يوفر أدوات لمراقبة تقدم عمليات التصنيع وتتبع الأداء بشكل مستمر، مما
            يسمح بتحديد المشكلات المحتملة واتخاذ الإجراءات التصحيحية بسرعة.
          </li>
          <li>
            <strong>إدارة الجودة :</strong> <br /> يتيح الموديول مراقبة جودة
            عمليات التصنيع وضمان الامتثال لمعايير الجودة المحددة، مع تسجيل
            البيانات وتحليلها لتحسين العمليات في المستقبل.
          </li>
          <li>
            <strong>إدارة المخزون :</strong> <br />
            يساهم الموديول في إدارة مخزون المواد الخام والمنتجات النهائية بشكل
            فعّال، مما يساعد في تجنب النقص أو الفائض في المخزون.
          </li>
          <li>
            <strong> تتبع التكاليف :</strong> <br /> يسهل الموديول تتبع التكاليف
            المرتبطة بعمليات التصنيع، بما في ذلك تكاليف المواد والعمالة
            والتكاليف العامة، مما يساعد في تحليل ربحية الإنتاج وتحسين إدارة
            التكاليف.
          </li>
        </ul>

        <p className='para'>
          باستخدام موديول التصنيع، تستطيع الشركات تحسين عمليات التصنيع وزيادة
          الإنتاجية والجودة، مما يساهم في تعزيز التنافسية وتحقيق أهداف الأعمال
          بشكل فعّال.
        </p>
      </>
    ),
  },
  {
    id: 8,
    title: 'نقاط البيع',
    image: '/images/solutions/next-erp/modules/module-12.png',
    content: (
      <>
        قد تتكون صناعة البيع بالتجزئة إما من سلسلة صغيرة من متاجر البيع بالتجزئة
        أو شركة كبيرة لها وجود عالمي مع مئات الآلاف من المتاجر التي تم إنشاؤها
        في أنحاء مختلفة من العالم. يمكن أن يخدم نظام نقاط البيع جميع متطلبات أي
        حجم من تجارة التجزئة على نحو فعال. يمكن أن يساعد برنامج تخطيط موارد
        المؤسسات للبيع بالتجزئة تجار التجزئة على إدارة العمليات بكفاءة من خلال
        توفير تدفق المعلومات باستمرار. كما انه يقدم دورًا حيويًا في عملك من أجل
        البقاء والازدهار مع زيادة الأرباح وتحسين الكفاءة
      </>
    ),
  },

  {
    id: 18,
    title: 'الفاتورة و الايصال الالكتروني',
    image: '/images/solutions/next-erp/modules/module-6.png',
    content: (
      <>
        <h5 className='mt-4'> الفاتورة الإلكترونية (e-invoice):</h5>

        <p>
          الفاتورة الإلكترونية تعتبر بديلًا رقميًا للفاتورة التقليدية المطبوعة.
          وتحتوي عادة على نفس المعلومات الواردة في الفاتورة الورقية، مثل تاريخ
          الفاتورة، البنود المفصلة للمنتجات أو الخدمات، والمبلغ الإجمالي. يمكن
          أن تتضمن الفاتورة الإلكترونية توقيعًا رقميًا أو شهادة رقمية للتأكد من
          صحتها وسلامتها.
        </p>

        <h5 className='mt-4'>الإيصال الإلكتروني (e-receipt):</h5>

        <p>
          يشير الإيصال الإلكتروني إلى وثيقة رقمية تؤكد استلام الدفعة النقدية أو
          المعاملة المالية. يتم إرسالها واستلامها عبر الإنترنت أو عبر البريد
          الإلكتروني. يحتوي الإيصال الإلكتروني على معلومات مثل تاريخ ووقت
          العملية المالية، والمبلغ المدفوع، وطريقة الدفع. كما هو الحال مع
          الفاتورة الإلكترونية، يمكن أن يحتوي الإيصال الإلكتروني على توقيع رقمي
          للتحقق من صحته.
        </p>

        <strong>
          وذلك بما يتماشى مع متطلبات هيئة الزكاة والدخل بالمملكة العربية
          السعودية كما يقوم فريق عملنا بمتابعة جميع التحديثات والمتطلبات الصادرة
          من الهيئة، وتضمينها في البرنامج بشكل فوري.
        </strong>
      </>
    ),
  },
  {
    id: 9,
    title: ' إدارة العقارات',
    image: '/images/solutions/next-erp/modules/module-5.png',
    content: (
      <>
        يهدف هذا الموديول إلى تسهيل وتنظيم جميع جوانب عمليات التطوير العقاري
        بدءًا من التخطيط وحتى التنفيذ والتسويق حيث يبدا من تقديم كافة المتطلبات
        الخاصة بنظام عمل تلك الشركات، خاصة إدارة العملاء والمشروعات وتنظيم
        المعاملات المالية بدقة منها أنظمة الدفع بالتقسيط الى متابعة الأداء بشكل
        دوري، مما يسمح بتحديد المشكلات واتخاذ الإجراءات التصحيحية عند الحاجة
        وبالتالي حل أي مشكلة قد تؤدي إلى فقد العميل أو احتمالية انتقاله إلى أحد
        من المنافسين.
      </>
    ),
  },
  {
    id: 10,
    title: ' المشاريع و ادارة المقاولات',
    image: '/images/solutions/next-erp/modules/module-11.png',
    content: (
      <>
        <p className='para'>
          موديل إدارة المشروعات والمقاولات في أنظمة ERP يسهم في تنظيم وإدارة
          العمليات المتعلقة بالمشاريع والمقاولات داخل الشركة. إليك بعض الأدوار
          الرئيسية التي يقوم بها هذا النوع من الوحدات في أنظمة ERP:
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong>تخطيط المشاريع:</strong> <br /> يتيح هذا الموديل للشركات
            تخطيط المشاريع بشكل شامل، بما في ذلك جدولة المهام، وتوزيع الموارد،
            وتحديد المواعيد النهائية والأهداف.
          </li>
          <li>
            <strong>إدارة الموارد والميزانية :</strong> <br /> يمكن لموديل إدارة
            المشاريع والمقاولات في أنظمة ERP تخصيص الموارد بكفاءة وفعالية،
            ومتابعة تكاليف المشروعات والميزانية المخصصة لها.
          </li>
          <li>
            <strong> تتبع التقدم والأداء :</strong> <br /> يتيح هذا الموديل تتبع
            تقدم المشاريع وأدائها عبر مختلف المعايير مثل الجدول الزمني،
            والميزانية، والجودة، وغيرها، مما يساعد في اتخاذ القرارات
            الاستراتيجية بشكل مستنير.
          </li>
          <li>
            <strong> تخطيط الموارد البشرية :</strong> <br /> يمكن لهذا الموديل
            تخطيط وتنظيم الموارد البشرية المطلوبة لتنفيذ المشاريع بنجاح، بما في
            ذلك تعيين المهام وتوزيع العمل.
          </li>
          <li>
            <strong> تحسين التنسيق والتعاون :</strong> <br /> يسهل هذا الموديل
            التنسيق والتعاون بين مختلف الأقسام والفرق داخل الشركة المشاركة في
            تنفيذ المشاريع، مما يعزز التواصل ويقلل من احتمالات الاشتباكات
            والتأخيرات.
          </li>
        </ul>

        <p className='para'>
          يلعب موديل إدارة المشاريع والمقاولات دورًا حيويًا في تحسين إدارة
          المشاريع وتنفيذها بكفاءة وفعالية داخل الشركات، مما يسهم في تحقيق
          الأهداف المحددة وزيادة الربحية وتحسين جودة الخدمات المقدمة.
        </p>
      </>
    ),
  },
  {
    id: 11,
    title: 'إدارة أسطول السيارات',
    image: '/images/solutions/next-erp/modules/module-4.png',
    content: (
      <>
        <p className='para'>
          إدارة أسطول السيارات هي استراتيجية إدارية تهدف إلى تنظيم وتنسيق جميع
          المركبات التابعة للشركة أو المؤسسة بهدف تحسين الكفاءة وتقليل التكاليف.
          يشمل ذلك جميع الأنشطة المتعلقة بالمركبات . تهدف إدارة أسطول السيارات
          إلى تحقيق الأهداف التالية:
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong> تحسين الكفاءة:</strong> <br /> من خلال تنظيم استخدام
            المركبات ، يمكن لإدارة أسطول السيارات زيادة الكفاءة وتقليل الفترات
            الفارغة والتكاليف الزائدة.
          </li>
          <li>
            <strong> تقليل التكاليف :</strong> <br /> عن طريق مراقبة استهلاك
            الوقود، والصيانة الدورية، وإدارة عمليات الصيانة بشكل فعال، يمكن
            لإدارة أسطول السيارات تقليل التكاليف الإجمالية للتشغيل. زيادة
            الأمان: بواسطة تتبع المركبات ، يمكن لإدارة أسطول ال
          </li>
          <li>
            <strong> زيادة الأمان :</strong> <br /> بواسطة تتبع المركبات ، يمكن
            لإدارة أسطول السيارات تحسين سلامة الموظفين والمركبات.
          </li>
          <li>
            <strong> تحسين الاستجابة :</strong> <br /> من خلال تحليل بيانات
            الأسطول، يمكن للشركات تحسين استجابتها لاحتياجات العملاء وتحسين خدمات
            التوصيل والتسليم.
          </li>
          <li>
            <strong> تقديم تقارير وتحليلات :</strong> <br /> يمكن لأنظمة إدارة
            أسطول السيارات توليد تقارير وتحليلات مفصلة حول أداء المركبات وتكاليف
            التشغيل، مما يساعد في اتخاذ القرارات الاستراتيجية وتحسين العمليات.
          </li>
        </ul>

        <p className='para'>
          إدارة أسطول السيارات تعتبر جزءًا أساسيًا من إدارة الموارد اللوجستية
          للشركات وتلعب دورًا هامًا في تحقيق أهداف الشركة وتعزيز الكفاءة
          والفعالية في العمليات التشغيلية.
        </p>
      </>
    ),
  },
];

const NOVA_HR_MODULES = [
  {
    id: 1,
    title: 'الهيكل التنظيمي',
    image: '/images/solutions/nova-hr/modules/module-8.png',
    content: (
      <>
        <p className='para'>
          يعتبر مديول الهيكل الوظيفي أحد الأدوات الرئيسية التي تسهم في تنظيم
          هيكل المؤسسة وتحديد السلطات والمسؤوليات.
        </p>

        <p className='para'>
          يقوم هذا المديول بالعديد من الأدوار الهامة في الشركات، منها:
        </p>

        <ul className='describe-list'>
          <li>
            <strong>تحديد الوظائف والمسؤوليات :</strong> <br /> يساعد مديول
            الهيكل الوظيفي في تحديد وتوثيق الوظائف داخل المؤسسة بما في ذلك
            المسميات الوظيفية والمسؤوليات المرتبطة بها.
          </li>
          <li>
            <strong>تنظيم هيكل التقارير الوظيفية :</strong> <br /> يساعد في
            تحديد العلاقات الهرمية بين الوظائف داخل المؤسسة وتنظيم تدفق البيانات
            والمعلومات بين الموظفين.
          </li>
          <li>
            <strong>تسهيل عمليات التوظيف والتوجيه الوظيفي :</strong> <br />
            يساعد على تحديد متطلبات كل وظيفة ومهاراتها المطلوبة، مما يسهل عمليات
            التوظيف واختيار المرشحين المناسبين وتوجيههم بشكل صحيح.
          </li>
          <li>
            <strong>تنظيم التدريب وتطوير الموظفين :</strong> <br /> يوفر إطارًا
            لتحديد احتياجات التدريب والتطوير لكل وظيفة بناءً على مهامها
            ومسؤولياتها، مما يساعد في تطوير مهارات الموظفين وتعزيز أدائهم.
          </li>
          <li>
            <strong> تحديد هيكل الأجور والمكافآت :</strong> <br /> يعمل على
            تحديد الهيكل الأساسي للأجور والمكافآت بناءً على درجات الوظائف
            والمسؤوليات المرتبطة بها
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    title: 'شئــون العاملين',
    image: '/images/solutions/nova-hr/modules/module-10.png',
    content: (
      <>
        <p className='para'>
          وظائف هذا المديول تشمل مجموعة واسعة من الأنشطة والمسؤوليات التي تسهم
          في تحقيق أهداف المؤسسة وتعزيز أداء الموظفين
        </p>

        <p className='para'>بعض الأدوار الرئيسية التي يقوم بها:</p>

        <ul className='describe-list'>
          <li>
            <strong>إدارة معلومات الموظفين:</strong> <br /> يقوم بتجميع وتحديث
            معلومات الموظفين بما في ذلك المعلومات الشخصية، والتعليمية، والخبرات
            العملية، والمهارات، والأداء الوظيفي.
          </li>
          <li>
            <strong> عمليات التوظيف والتعيين :</strong> <br /> يقوم بإدارة
            عمليات التوظيف من تحديد الاحتياجات، ونشر الوظائف، وفرز السير
            الذاتية، وإجراء المقابلات، واختيار المرشحين المناسبين.
          </li>
          <li>
            <strong>إدارة الأداء وتقييم الأداء :</strong> <br /> يساعد في تنفيذ
            عمليات تقييم الأداء للموظفين بشكل دوري، وتحديد نقاط القوة والضعف،
            وتقديم التغذية الراجعة لتطوير الأداء.
          </li>
          <li>
            <strong>إدارة التدريب والتطوير :</strong> <br /> يقوم بتحديد
            احتياجات التدريب للموظفين وتنظيم برامج التطوير المهني والتدريبية
            لزيادة مهاراتهم وتعزيز أدائهم.
          </li>
          <li>
            <strong>إدارة الرواتب والمكافآت :</strong> <br /> يتولى تحديد هيكل
            الرواتب والمكافآت وإدارة عمليات دفع الرواتب والمزايا الإضافية
            والمكافآت للموظفين.
          </li>
          <li>
            <strong>إدارة العلاقات العمالية :</strong> <br /> يسهل حل النزاعات
            بين الموظفين وإدارة العلاقات مع النقابات والجهات النقابية.
          </li>
          <li>
            <strong>الامتثال للقوانين واللوائح العمالية :</strong> <br /> يضمن
            التماشي مع القوانين واللوائح المحلية والدولية المتعلقة بحقوق بحقوق
            الموظف والسلامة والصحة المهنية.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    title: 'الحضــور والانصـــراف',
    image: '/images/solutions/nova-hr/modules/module-5.png',
    content: (
      <>
        <p className='para'>إدارة وتتبع وقت العمل للموظفين في الشركات. </p>

        <p className='para'>
          وظائف هذا المديول تشمل العديد من الأنشطة والمسؤوليات التي تسهم في
          تنظيم وتحسين إدارة الوقت والحضور والانصراف، وتشمل:
        </p>

        <ul className='describe-list'>
          <li>
            <strong>تسجيل الحضور والانصراف:</strong> <br /> يتيح المديول
            للموظفين تسجيل دخولهم وخروجهم من العمل بشكل دقيق، سواءً كان ذلك عن
            طريق أنظمة البصمة الحيوية، أو البطاقات الذكية، أو التطبيقات
            الإلكترونية.
          </li>
          <li>
            <strong>تتبع ساعات العمل :</strong> <br /> يسجل المديول ساعات الحضور
            والانصراف لكل موظف بدقة، مما يسهل عمليات تحديد الوقت المستغل في
            العمل وتقدير ساعات العمل الإضافية.
          </li>
          <li>
            <strong>إدارة الإجازات والغياب :</strong> <br /> يتيح المديول
            للموظفين طلب الإجازات وتسجيل الغيابات بسهولة، ويسمح للمديرين بمراجعة
            هذه الطلبات وإدارتها بشكل فعال.
          </li>
          <li>
            <strong>تقارير وتحليلات الحضور والانصراف :</strong> <br /> يوفر
            المديول تقارير وتحليلات مفصلة حول ساعات الحضور والانصراف، مما يساعد
            الإدارة في مراقبة أداء الموظفين وتحليل نمط الحضور والغياب.
          </li>
          <li>
            <strong>الامتثال للسياسات والقوانين العمالية :</strong> <br /> يساعد
            المديول في ضمان الامتثال للسياسات والقوانين المحلية والدولية
            المتعلقة بساعات العمل والإجازات والحضور والانصراف.
          </li>
          <li>
            <strong>تكامل مع أنظمة الرواتب والمكافآت :</strong> <br /> يتيح
            المديول تكامله مع أنظمة الرواتب والمكافآت لضمان دقة حساب الرواتب
            والمزايا الإضافية بناءً على ساعات العمل الفعلية.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    title: 'الرواتب والبدلات',
    image: '/images/solutions/nova-hr/modules/module-7.png',
    content: (
      <>
        <p className='para'>
          تسهيل عمليات إعداد الرواتب وتقديم المكافآت بشكل دقيق وفعال
        </p>

        <p className='para'>ويشمل العديد من الوظائف والمسؤوليات التي تشمل: </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong> حساب الرواتب والمكافآت :</strong> <br /> يقوم المديول بحساب
            مرتبات الموظفين بناءً على البيانات المدخلة مثل ساعات العمل،
            الإجازات، والبدلات المختلفة، والضرائب والتأمينات الاجتماعية.
          </li>
          <li>
            <strong> إدارة بيانات الموظفين :</strong> <br /> يتيح المديول إدارة
            بيانات الموظفين المتعلقة بالرواتب والبدلات بما في ذلك المعلومات
            الشخصية، وتفاصيل الرواتب، والضمانات الاجتماعية، والضرائب.
          </li>
          <li>
            <strong> تقديم التقارير والبيانات المالية :</strong> <br /> يوفر
            المديول تقارير مالية دقيقة ومفصلة حول تكلفة الرواتب والمكافآت، ويمكن
            أن يتكامل مع أنظمة المحاسبة الخاصة بالشركة لتوليد التقارير المالية
            الشاملة.
          </li>
          <li>
            <strong> إدارة البدلات والمزايا الإضافية :</strong> <br /> يساعد
            المديول في إدارة البدلات والمزايا الإضافية للموظفين مثل بدلات النقل،
            والإيجار، والتأمين الطبي، والمكافآت السنوية، وغيرها.
          </li>
          <li>
            <strong> امتثال القوانين والتشريعات المالية :</strong> <br /> يساعد
            المديول في ضمان الامتثال للقوانين والتشريعات المالية المحلية
            والدولية المتعلقة بدفع الرواتب والضرائب.
          </li>
          <li>
            <strong> تكامل مع أنظمة الإدارة الشاملة :</strong> <br /> يتيح
            المديول التكامل مع أنظمة الإدارة الشاملة الأخرى مثل إدارة الموارد
            البشرية والحضور والانصراف لتوفير إدارة متكاملة للموارد البشرية.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 5,
    title: 'الخدمة الذاتية للموظفين',
    image: '/images/solutions/nova-hr/modules/module-6.png',
    content: (
      <>
        <p className='para'>
          تمكين الموظفين من إدارة علاقتهم مع الشركة والوصول إلى مجموعة متنوعة من
          الخدمات والمعلومات بسهولة وفعالية.
        </p>

        <p className='para'>
          إليك بعض الأدوار التي يقوم بها هذا المديول في الشركات:
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong>إدارة الملف الشخصي للموظف:</strong> <br /> يتيح للموظفين
            تحديث معلوماتهم الشخصية والتعليمية والمهنية بنفسهم من خلال التطبيق،
            مما يسهل على الشركة تحديث البيانات بشكل دوري ودقيق.
          </li>
          <li>
            <strong>طلب الإجازات والموافقات :</strong> <br /> يمكن للموظفين طلب
            الإجازات والأذونات والموافقات الأخرى عن طريق التطبيق، ويتلقون
            تحديثات فورية بشأن حالة الطلبات.
          </li>
          <li>
            <strong>عرض الرواتب والبدلات :</strong> <br /> يتيح التطبيق للموظفين
            عرض كشوف الرواتب والمكافآت بشكل سريع ومباشر، ويمكنهم الوصول إلى
            معلومات عن البدلات والمزايا الإضافية التي يستحقونها.
          </li>
          <li>
            <strong>تقديم التقارير والمستندات الهامة :</strong> <br /> يتيح
            التطبيق للموظفين الوصول إلى التقارير والمستندات الهامة مثل شروط
            العمل وسياسات الشركة ونماذج الطلبات بسهولة.
          </li>
          <li>
            <strong>التواصل والتفاعل مع الإدارة والزملاء :</strong> <br /> يوفر
            التطبيق وسيلة للتواصل الفعّال بين الموظفين والإدارة والزملاء، سواءً
            كان ذلك عبر الرسائل النصية أو الدردشة الفورية.
          </li>
          <li>
            <strong> تقديم الاقتراحات والشكاوى :</strong> <br /> يتيح التطبيق
            للموظفين تقديم الاقتراحات والشكاوى بسهولة، ويتمكنون من تتبع حالة
            معالجة الشكاوى والاستفسارات الخاصة بهم.
          </li>
          <li>
            <strong>تقديم التدريب والتطوير :</strong> <br /> يمكن للموظفين البحث
            عن البرامج التدريبية والتطويرية المتاحة لهم والتسجيل فيها من خلال
            التطبيق.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    title: 'تقييـــم الأداء',
    image: '/images/solutions/nova-hr/modules/module-9.png',
    content: (
      <>
        <p className='para'>
          يعمل مديول تقييم الأداء في نظام nova HR على توفير إطار شامل لقياس
          وتقييم وتطوير أداء الموظفين، من خلال الوظائف التاليه
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong>وضع أهداف ومعايير الأداء:</strong> <br /> يساعد المديول في
            تحديد أهداف الأداء الفردية والمؤسسية وتحديد المعايير المناسبة لقياس
            أداء الموظفين
          </li>
          <li>
            <strong>تقييم الأداء السنوي أو الدوري :</strong> <br /> يتيح المديول
            للمديرين تقييم أداء الموظفين بناءً على المعايير المحددة، وتوفير
            تغذية راجعة بناءً على الأداء والمساهمات.
          </li>
          <li>
            <strong>توفير مراجعات مستمرة :</strong> <br /> : يمكن للمديرين
            والموظفين استخدام المديول لتبادل التغذية الراجعة والملاحظات على أداء
            الموظفين بشكل منتظم، وتوثيق هذه المراجعات.
          </li>
          <li>
            <strong>تطوير المهارات والتحسين المستمر :</strong> <br /> يساهم
            المديول في تحديد احتياجات التطوير والتدريب للموظفين بناءً على نتائج
            تقييم الأداء، وتوفير الفرص المناسبة لتعزيز مهاراتهم وتحسين أدائهم.
          </li>
          <li>
            <strong>ربط الأداء بالمكافآت والترقيات :</strong> <br /> يمكن
            للمديرين استخدام نتائج تقييم الأداء لاتخاذ قرارات بشأن المكافآت
            والترقيات والتطوير المهني للموظفين.
          </li>
          <li>
            <strong> تقديم الاقتراحات والشكاوى :</strong> <br /> يتيح التطبيق
            للموظفين تقديم الاقتراحات والشكاوى بسهولة، ويتمكنون من تتبع حالة
            معالجة الشكاوى والاستفسارات الخاصة بهم.
          </li>
          <li>
            <strong>تحليل البيانات والتقارير الإدارية :</strong> <br /> يوفر
            المديول تقارير وتحليلات مفصلة حول أداء الموظفين واتجاهات الأداء على
            مستوى الشركة، مما يسهل عمليات اتخاذ القرارات الإدارية.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 7,
    title: 'التدريب',
    image: '/images/solutions/nova-hr/modules/module-3.png',
    content: (
      <>
        <p className='para'>
          يسهم مديول التدريب في nova HR في تطوير وتعزيز مهارات الموظفين وزيادة
          كفاءتهم وفعاليتهم في أداء مهامهم بشكل أفضل، مما يساهم في تحقيق أهداف
          الشركة بشكل أكبر من خلال الوظائف التاليه :
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong>تحديد احتياجات التدريب:</strong> <br /> يساعد المديول في
            تحديد احتياجات التدريب للموظفين بناءً على أهداف الشركة ومتطلبات
            الوظائف، سواء كانت تطوير مهارات جديدة أو تحسين المهارات الحالية.
          </li>
          <li>
            <strong>تصميم البرامج التدريبية :</strong> <br /> يتيح المديول لفرق
            إدارة الموارد البشرية تصميم برامج تدريبية مخصصة تتناسب مع احتياجات
            الموظفين وأهداف الشركة، سواء كانت داخلية أو خارجية.
          </li>
          <li>
            <strong>إدارة الدورات التدريبية :</strong> <br /> يساعد المديول في
            تنظيم وإدارة الدورات التدريبية، بما في ذلك التسجيل، وجدولة الدورات،
            وتوزيع المواد التدريبية، وتقديم التقييمات.
          </li>
          <li>
            <strong>تقييم أداء التدريب :</strong> <br /> يوفر المديول آليات
            لتقييم أداء البرامج التدريبية، بما في ذلك تقييم رضا الموظفين وتحليل
            فعالية البرنامج وقياس التغيير في المهارات بعد التدريب.
          </li>
          <li>
            <strong>تتبع التقدم والتقارير :</strong> <br /> يتيح المديول تتبع
            تقدم الموظفين في الدورات التدريبية وإنجازاتهم، وتوليد تقارير
            وتحليلات حول أداء التدريب وفعاليته.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 8,
    title: 'التوظيف',
    image: '/images/solutions/nova-hr/modules/module-4.png',
    content: (
      <>
        <p className='para'>
          يقدم مديول التوظيف في nova HR دعما شاملاً لعملية التوظيف من بداية نشر
          الوظائف إلى اختيار المرشحين المناسبين، مما يساهم في جعل هذه العملية
          فعالة وفعّالة وموجهة نحو الهدف من خلال الوظائف التاليه :
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong>نشر الوظائف وإدارة الطلبات:</strong> <br /> يتيح المديول نشر
            الوظائف الشاغرة داخل الشركة أو على منصات التوظيف الخارجية، ويدير
            عملية استقبال الطلبات وفرزها بناءً على المعايير المحددة.
          </li>
          <li>
            <strong>إدارة قوائم الانتظار والمرشحين :</strong> <br /> يساعد
            المديول في إدارة قوائم الانتظار للمرشحين وتتبع مراحل التوظيف بدقة،
            بما في ذلك المرشحين النشطين والمرشحين المحتملين.
          </li>
          <li>
            <strong>تقييم المرشحين والمقابلات :</strong> <br /> يوفر المديول
            أدوات لتقييم المرشحين وجدارات لتنظيم وإجراء المقابلات الشخصية وتسجيل
            تقارير المقابلات.
          </li>
          <li>
            <strong>التواصل مع المرشحين :</strong> <br /> يسهل المديول التواصل
            مع المرشحين من خلال الرسائل الإلكترونية أو الرسائل النصية لتحديد
            مواعيد المقابلات أو تقديم التحديثات حول حالة طلب التوظيف.
          </li>
          <li>
            <strong>إدارة عروض العمل والمفاضلة :</strong> <br /> يتيح المديول
            إدارة عملية تقديم العروض للمرشحين الناجحين ومتابعة مراحل المفاضلة
            بين العروض المقدمة.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 9,
    title: 'سلسلة الموافقات',
    image: '/images/solutions/nova-hr/modules/module-2.png',
    content: (
      <>
        <p className='para'>
          من اهم مميزات نظام nova HR انه يعمل على امكانية انشاء مستويات عديدة من
          الصلاحيات على مستوى كل طلب لكل مستخدم
        </p>

        <p className='para'>
          ومن اهم الادوار الذي يقوم بها مديول ال workflow تنظيم وتنسيق عمليات
          الموارد البشرية داخل الشركات من خلال الوظائف التالية:
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong> تنسيق عمليات التوظيف والتوجيه:</strong> <br /> يسهل المديول
            تنظيم عمليات التوظيف بشكل متسلسل، بدءًا من استلام طلب التوظيف وصولاً
            إلى اختيار المرشح النهائي وإصدار العروض.
          </li>
          <li>
            <strong>تسهيل عمليات الموافقات والمراجعات:</strong> <br /> يمكن
            للمديول تنظيم عمليات الموافقات والمراجعات الداخلية، مثل الموافقة على
            طلبات الإجازة، والمراجعة الدورية لأداء الموظفين.
          </li>
          <li>
            <strong>توجيه عمليات التدريب والتطوير :</strong> <br /> يمكن للمديول
            توجيه سير العمل لتقديم طلبات التدريب والتطوير ومتابعتها حتى الانتهاء
            منها بنجاح.
          </li>
          <li>
            <strong>متابعة العمليات وتحديد المسارات البديلة :</strong> <br />
            يسمح المديول بمتابعة تقدم العمليات وتحديد المسارات البديلة في حالة
            الحاجة إلى تغييرات أو تعديلات.
          </li>
          <li>
            <strong>إشعارات وتنبيهات :</strong> <br /> يقدم المديول إشعارات
            وتنبيهات للموظفين والمديرين بشأن العمليات الجديدة أو الحاجة إلى
            إجراءات فورية.
          </li>
          <li>
            <strong>إشعارات وتنبيهات توليد تقارير وتحليلات :</strong> <br />{' '}
            يمكن للمديول توليد تقارير وتحليلات حول أداء العمليات وزمن الاستجابة
            والكفاءة، مما يساعد في تحسينها مستقبلا.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 10,
    title: 'الحضور و الانصراف عبر الموقع الجغرافي',
    image: '/images/solutions/nova-hr/modules/module-1.png',
    content: (
      <>
        <p className='para'>
          يوفر نظام nova HR مديول الحضور و الانصراف من خلال الموقع الجغرافي و
          الذي يساعد الشركات على
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong> تسجيل الحضور والانصراف بدقة:</strong> <br /> يستخدم المديول
            GPS تسجيل مواقع الموظفين عند دخولهم إلى موقع العمل وعند مغادرتهم.
            هذا يساعد على تتبع ساعات العمل بدقة وتسجيل الحضور والانصراف بشكل
            دقيق.
          </li>
          <li>
            <strong>مراقبة التواجد والنشاط:</strong> <br /> يمكن لنظام GPS توفير
            معلومات مفصلة حول تواجد الموظفين ونشاطهم خلال فترات العمل. يمكن
            لإدارة الموارد البشرية استخدام هذه المعلومات لتحليل أداء الموظفين
            وتحسين إدارة الوقت.
          </li>
          <li>
            <strong>زيادة الكفاءة والأمان :</strong> <br /> بفضل تقنية GPS، يمكن
            للشركات تحسين كفاءة إدارة الحضور والانصراف وتقليل الأخطاء البشرية
            المحتملة في تسجيل البيانات. كما يمكن استخدامه لضمان سلامة الموظفين
            عند العمل في مواقع بعيدة أو خطيرة.
          </li>
          <li>
            <strong>توفير بيانات دقيقة للرواتب والمحاسبة :</strong> <br /> يمكن
            استخدام بيانات الحضور والانصراف التي تم تسجيلها بواسطة نظام GPS
            لحساب الرواتب والمزايا بدقة، مما يقلل من الأخطاء والخلافات في عملية
            الرواتب والمحاسبة.
          </li>
        </ul>
      </>
    ),
  },
];

const DYNAMIC_MODULES = [
  {
    id: 1,
    title: 'ادارة معرض السيارات',
    image: '/images/solutions/dynamic/modules/module-3.png',
    content: (
      <>
        <p className='para'>
          يوفر النظام واجهة مستخدم سهلة الاستخدام لموظفي المبيعات لإدارة عمليات
          البيع بشكل فعال، بدءًا من استقبال العملاء وتسجيل المعلومات الخاصة بهم،
          واستقبال طلبات الشراء، وإنشاء عروض الأسعار، ومتابعة حالات البيع حتى
          إغلاق الصفقة.
        </p>

        <p className='para'>
          يسهل النظام مراقبة عمليات المبيعات وتحليل أدائها من خلال تقارير مخصصة
          توفر رؤى حول عدد الصفقات المغلقة، والإيرادات، والمبيعات بحسب نوع
          السيارة وغيرها من المعايير.
        </p>

        <ul className='describe-list'>
          <li>
            <strong>التسويق :</strong> <br /> يتيح النظام إدارة حملات التسويق
            والإعلانات لمعرض السيارات، بما في ذلك الترويج للعروض الخاصة،
            والحملات الترويجية، وتسويق الموديلات الجديدة.
            <br />
            يتضمن النظام أدوات لتحليل فعالية حملات التسويق، مما يسمح بتحديد
            الاستراتيجيات الأكثر نجاحًا وتحسين استراتيجيات التسويق المستقبلية.
          </li>
          <li>
            <strong>إدارة المخزون :</strong> <br /> يسهل الموديول جدولة عمليات
            الإنتاج بشكل فعّال، مما يساعد في تحديد الجداول الزمنية وتخصيص
            الموارد بشكل مثلى.
            <br />
            يتيح النظام مراقبة حركة المخزون، وتحديث المعلومات بشكل فوري، وتوليد
            تقارير حول مستوى المخزون ودورة الحياة للمنتجات.
          </li>
          <li>
            <strong>إدارة علاقات العملاء :</strong> <br /> يسهل النظام تتبع
            وإدارة بيانات العملاء، بما في ذلك تاريخ الاتصالات والمعاملات السابقة
            والاحتياجات المحتملة.
            <br />
            يوفر النظام أدوات لإدارة حملات البريد الإلكتروني والرسائل النصية
            وغيرها من الأنشطة التسويقية الموجهة للعملاء الحاليين والمحتملين.
          </li>
        </ul>

        <p className='para'>
          من خلال هذه الوظائف، يوفر نظام معارض السيارات حلاً شاملاً لإدارة
          وتنظيم عمليات معرض السيارات، مما يساعد على تحسين كفاءة العمليات وتعزيز
          تجربة العملاء وزيادة الإيرادات.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: 'ادارة شركات الشحن',
    image: '/images/solutions/dynamic/modules/module-1.png',
    content: (
      <>
        <p className='para'>
          يهدف نظام إدارة شركات الشحن إلى تحسين كفاءة العمليات وتقليل التكاليف
          وزيادة الربحية، وذلك من خلال توفير وسائل لتتبع الشحنات، وتخطيط الطرق
          والمسارات الأمثل، وإدارة المخزون، وتنظيم النقل والتوزيع، وتسهيل
          التواصل مع العملاء وتلبية احتياجاتهم بشكل فعال.
        </p>

        <p className='para'>
          يتضمن نظام إدارة شركات الشحن مجموعة من الوظائف والميزات مثل:
        </p>

        <ul className='describe-list'>
          <li>
            <strong>تتبع الشحنات :</strong> <br /> يمكن للنظام توفير معلومات
            محدَّثة عن موقع وحالة الشحنات، مما يساعد على تقديم خدمة أفضل للعملاء
            وتحسين إدارة الوقت والموارد.
          </li>
          <li>
            <strong>التخطيط والجدولة :</strong> <br /> يمكن للنظام تحليل
            البيانات وتحديد أفضل الطرق والمسارات لنقل البضائع بأقل تكلفة وفي
            أسرع وقت ممكن.
          </li>
          <li>
            <strong>إدارة المخزون :</strong> <br /> يتيح النظام تتبع المخزون
            وإدارته بكفاءة، بما في ذلك تخزين البضائع، وإعادة التوريد، وتوزيعها
            وفقًا للاحتياجات.
          </li>
          <li>
            <strong>التواصل وخدمة العملاء :</strong> <br /> يساعد النظام في
            تسهيل التواصل مع العملاء وتلبية استفساراتهم ومتطلباتهم بشكل فعّال،
            مما يساهم في بناء علاقات عمل مستدامة.
          </li>
        </ul>

        <p className='para'>
          يتنوع نطاق وميزات نظام إدارة شركات الشحن وفقًا لاحتياجات ومتطلبات كل
          شركة، ويمكن أن يشمل أنظمة إدارة العلاقات مع العملاء (CRM)، وأنظمة تتبع
          الشحنات (TMS)، وأنظمة إدارة المخزون (IMS)، وأنظمة تخطيط الموارد
          المؤسسية (ERP)، والعديد من التطبيقات والأدوات التي تهدف إلى تحسين أداء
          الشركات اللوجستية وزيادة فعالية عملياتها.
        </p>
      </>
    ),
  },
  {
    id: 3,
    title: 'ادارة العيادات',
    image: '/images/solutions/dynamic/modules/module-2.png',
    content: (
      <>
        <p className='para'>
          تتضمن وظائف نظام إدارة العيادات مجموعة من الأدوات والميزات التي تساعد
          في تنظيم وتشغيل العيادات بفعالية، ومنها:
        </p>

        <ul className='describe-list'>
          <li>
            <strong>لجدولة وإدارة المواعيد :</strong> <br /> يساعد النظام في
            جدولة المواعيد للمرضى وإدارتها بكفاءة، وذلك من خلال تحديد مواعيد
            الزيارات للأطباء وتوزيع المواعيد بشكل متوازن لتجنب الزحام والانتظار
            الطويل.
          </li>
          <li>
            <strong>إدارة الملفات الطبية :</strong> <br /> يتيح النظام تخزين
            وإدارة معلومات المرضى والتقارير الطبية بشكل آمن ومنظم، مما يسهل
            الوصول إليها عند الحاجة ويساعد في تقديم الرعاية الصحية الشخصية لكل
            مريض.
          </li>
          <li>
            <strong>الفواتير والمدفوعات :</strong> <br /> يمكن لنظام إدارة
            العيادات إنشاء فواتير للخدمات الطبية المقدمة وتتبع المدفوعات من
            المرضى وتسجيلها بدقة.
          </li>
          <li>
            <strong>الاتصال والتواصل :</strong> <br /> يساعد النظام في تسهيل
            التواصل بين الطاقم الطبي والمرضى، سواء عبر الرسائل النصية أو البريد
            الإلكتروني، وتوفير إشعارات التذكير بالمواعيد والمواعيد القادمة.
          </li>
          <li>
            <strong> التقارير والتحليلات :</strong> <br /> يوفر النظام تقارير
            وتحليلات دقيقة حول أداء العيادة واستخدام الموارد ومستوى رضا المرضى،
            مما يساعد في اتخاذ القرارات الإدارية الصحيحة وتحسين أداء العيادة
            بشكل مستمر.
          </li>
        </ul>

        <p className='para'>
          يعتبر نظام إدارة العيادات أداة أساسية لتحسين كفاءة وجودة الخدمات
          الطبية المقدمة، وتسهيل عمليات الإدارة والتواصل، وتحسين تجربة المرضى في
          العيادات الطبية.
        </p>
      </>
    ),
  },
  {
    id: 4,
    title: 'الفاتورة و الايصال الالكتروني',
    image: '/images/solutions/dynamic/modules/module-6.png',
    content: (
      <>
        <h5 className='mt-4'> الفاتورة الإلكترونية (e-invoice):</h5>

        <p>
          الفاتورة الإلكترونية تعتبر بديلًا رقميًا للفاتورة التقليدية المطبوعة.
          وتحتوي عادة على نفس المعلومات الواردة في الفاتورة الورقية، مثل تاريخ
          الفاتورة، البنود المفصلة للمنتجات أو الخدمات، والمبلغ الإجمالي. يمكن
          أن تتضمن الفاتورة الإلكترونية توقيعًا رقميًا أو شهادة رقمية للتأكد من
          صحتها وسلامتها.
        </p>

        <h5 className='mt-4'>الإيصال الإلكتروني (e-receipt):</h5>

        <p>
          يشير الإيصال الإلكتروني إلى وثيقة رقمية تؤكد استلام الدفعة النقدية أو
          المعاملة المالية. يتم إرسالها واستلامها عبر الإنترنت أو عبر البريد
          الإلكتروني. يحتوي الإيصال الإلكتروني على معلومات مثل تاريخ ووقت
          العملية المالية، والمبلغ المدفوع، وطريقة الدفع. كما هو الحال مع
          الفاتورة الإلكترونية، يمكن أن يحتوي الإيصال الإلكتروني على توقيع رقمي
          للتحقق من صحته.
        </p>

        <strong>
          وذلك بما يتماشى مع متطلبات هيئة الزكاة والدخل بالمملكة العربية
          السعودية كما يقوم فريق عملنا بمتابعة جميع التحديثات والمتطلبات الصادرة
          من الهيئة، وتضمينها في البرنامج بشكل فوري.
        </strong>
      </>
    ),
  },
  {
    id: 5,
    title: ' إدارة العقارات',
    image: '/images/solutions/dynamic/modules/module-5.png',
    content: (
      <>
        يهدف هذا الموديول إلى تسهيل وتنظيم جميع جوانب عمليات التطوير العقاري
        بدءًا من التخطيط وحتى التنفيذ والتسويق حيث يبدا من تقديم كافة المتطلبات
        الخاصة بنظام عمل تلك الشركات، خاصة إدارة العملاء والمشروعات وتنظيم
        المعاملات المالية بدقة منها أنظمة الدفع بالتقسيط الى متابعة الأداء بشكل
        دوري، مما يسمح بتحديد المشكلات واتخاذ الإجراءات التصحيحية عند الحاجة
        وبالتالي حل أي مشكلة قد تؤدي إلى فقد العميل أو احتمالية انتقاله إلى أحد
        من المنافسين.
      </>
    ),
  },
  {
    id: 6,
    title: ' المشاريع و ادارة المقاولات',
    image: '/images/solutions/dynamic/modules/module-11.png',
    content: (
      <>
        <p className='para'>
          موديل إدارة المشروعات والمقاولات في أنظمة ERP يسهم في تنظيم وإدارة
          العمليات المتعلقة بالمشاريع والمقاولات داخل الشركة. إليك بعض الأدوار
          الرئيسية التي يقوم بها هذا النوع من الوحدات في أنظمة ERP:
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong>تخطيط المشاريع:</strong> <br /> يتيح هذا الموديل للشركات
            تخطيط المشاريع بشكل شامل، بما في ذلك جدولة المهام، وتوزيع الموارد،
            وتحديد المواعيد النهائية والأهداف.
          </li>
          <li>
            <strong>إدارة الموارد والميزانية :</strong> <br /> يمكن لموديل إدارة
            المشاريع والمقاولات في أنظمة ERP تخصيص الموارد بكفاءة وفعالية،
            ومتابعة تكاليف المشروعات والميزانية المخصصة لها.
          </li>
          <li>
            <strong> تتبع التقدم والأداء :</strong> <br /> يتيح هذا الموديل تتبع
            تقدم المشاريع وأدائها عبر مختلف المعايير مثل الجدول الزمني،
            والميزانية، والجودة، وغيرها، مما يساعد في اتخاذ القرارات
            الاستراتيجية بشكل مستنير.
          </li>
          <li>
            <strong> تخطيط الموارد البشرية :</strong> <br /> يمكن لهذا الموديل
            تخطيط وتنظيم الموارد البشرية المطلوبة لتنفيذ المشاريع بنجاح، بما في
            ذلك تعيين المهام وتوزيع العمل.
          </li>
          <li>
            <strong> تحسين التنسيق والتعاون :</strong> <br /> يسهل هذا الموديل
            التنسيق والتعاون بين مختلف الأقسام والفرق داخل الشركة المشاركة في
            تنفيذ المشاريع، مما يعزز التواصل ويقلل من احتمالات الاشتباكات
            والتأخيرات.
          </li>
        </ul>

        <p className='para'>
          يلعب موديل إدارة المشاريع والمقاولات دورًا حيويًا في تحسين إدارة
          المشاريع وتنفيذها بكفاءة وفعالية داخل الشركات، مما يسهم في تحقيق
          الأهداف المحددة وزيادة الربحية وتحسين جودة الخدمات المقدمة.
        </p>
      </>
    ),
  },
  {
    id: 7,
    title: 'إدارة أسطول السيارات',
    image: '/images/solutions/dynamic/modules/module-4.png',
    content: (
      <>
        <p className='para'>
          إدارة أسطول السيارات هي استراتيجية إدارية تهدف إلى تنظيم وتنسيق جميع
          المركبات التابعة للشركة أو المؤسسة بهدف تحسين الكفاءة وتقليل التكاليف.
          يشمل ذلك جميع الأنشطة المتعلقة بالمركبات . تهدف إدارة أسطول السيارات
          إلى تحقيق الأهداف التالية:
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong> تحسين الكفاءة:</strong> <br /> من خلال تنظيم استخدام
            المركبات ، يمكن لإدارة أسطول السيارات زيادة الكفاءة وتقليل الفترات
            الفارغة والتكاليف الزائدة.
          </li>
          <li>
            <strong> تقليل التكاليف :</strong> <br /> عن طريق مراقبة استهلاك
            الوقود، والصيانة الدورية، وإدارة عمليات الصيانة بشكل فعال، يمكن
            لإدارة أسطول السيارات تقليل التكاليف الإجمالية للتشغيل. زيادة
            الأمان: بواسطة تتبع المركبات ، يمكن لإدارة أسطول ال
          </li>
          <li>
            <strong> زيادة الأمان :</strong> <br /> بواسطة تتبع المركبات ، يمكن
            لإدارة أسطول السيارات تحسين سلامة الموظفين والمركبات.
          </li>
          <li>
            <strong> تحسين الاستجابة :</strong> <br /> من خلال تحليل بيانات
            الأسطول، يمكن للشركات تحسين استجابتها لاحتياجات العملاء وتحسين خدمات
            التوصيل والتسليم.
          </li>
          <li>
            <strong> تقديم تقارير وتحليلات :</strong> <br /> يمكن لأنظمة إدارة
            أسطول السيارات توليد تقارير وتحليلات مفصلة حول أداء المركبات وتكاليف
            التشغيل، مما يساعد في اتخاذ القرارات الاستراتيجية وتحسين العمليات.
          </li>
        </ul>

        <p className='para'>
          إدارة أسطول السيارات تعتبر جزءًا أساسيًا من إدارة الموارد اللوجستية
          للشركات وتلعب دورًا هامًا في تحقيق أهداف الشركة وتعزيز الكفاءة
          والفعالية في العمليات التشغيلية.
        </p>
      </>
    ),
  },
];

const STATUS = [
  {
    id: 1,
    title: 'عدد سنوات الخبرة',
    endCount: 15,
    image: '/images/icons/employee.svg',
    wowDuration: '1.5s',
    wowDelay: '0.2s',
  },
  {
    id: 2,
    title: 'المشاريع',
    endCount: 75,
    image: '/images/icons/review.svg',
    wowDuration: '1.5s',
    wowDelay: '0.4s',
  },
  {
    id: 3,
    title: 'عملائنا',
    endCount: 500,
    image: '/images/icons/smily.svg',
    wowDuration: '1.5s',
    wowDelay: '0.4s',
  },
  {
    id: 4,
    title: 'قصص النجاح',
    endCount: 450,
    image: '/images/icons/comment.svg',
    wowDuration: '1.5s',
    wowDelay: '0.6s',
  },
];

const SOLUTIONS = [
  {
    id: 'erp-next',
    title: 'ERP Next System',
    image: '/images/solutions/solution-2.png',
    info: 'يوفر نظام ERPNext إدارة ومتابعة كاملة لأقسام الشركة مع إمكانية الحصول على تقارير تفصيلية وشاملة لجميع الأنشطة والأقسام للتعرف على الأداء الفعلي',
    extraInfo: (
      <div className='describe-content'>
        <p className='para'>
          نظام ERPNext هو نظام متكامل يوفر أدوات وميزات تساعد الشركات على إدارة
          جميع جوانب عملياتها بكفاءة وفعالية، مما يعزز القدرة على المنافسة
          والنمو
        </p>

        <p className='para'>مميزات نظام ERPNext:</p>

        <ul className='describe-list'>
          <li>
            <strong> واجهة سهلة الاستخدام </strong> : تصميم سهل الاستخدام يتيح
            للمستخدمين التفاعل مع النظام بسهولة.
          </li>
          <li>
            <strong> دعم متعدد اللغات والعملات </strong> : دعم العديد من اللغات
            والعملات لتلبية احتياجات الشركات العالمية.
          </li>
          <li>
            <strong> تقارير شاملة </strong> : تقديم تقارير مفصلة عن جميع جوانب
            العمل.
          </li>
          <li>
            <strong> أتمتة العمليات </strong> : تحسين وتنسيق العمليات التشغيلية
            اليومية، وأتمتة الإجراءات الروتينية.
          </li>

          <li>
            <strong> تحليل البيانات </strong> : أدوات تحليلية لمساعدة الإدارة في
            اتخاذ قرارات مستنيرة.
          </li>
          <li>
            <strong> أمان البيانات </strong> : نظام أمني متقدم لحماية البيانات
            الحساسة ومنع الوصول غير المصرح به.
          </li>
          <li>
            <strong> تكامل الأنظمة </strong> : إمكانية التكامل مع أنظمة وتطبيقات
            خارجية.
          </li>
          <li>
            <strong> التخصيص والتوسيع </strong> : إمكانية تخصيص النظام وفقًا
            لاحتياجات الشركة الخاصة، وإضافة وحدات جديدة عند الحاجة.
          </li>
          <li>
            <strong> تطبيقات الهاتف المحمول </strong> : تطبيقات مخصصة للأجهزة
            المحمولة تتيح الوصول إلى النظام من أي مكان وفي أي وقت.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 'nova-hr',
    title: 'NOVA HR System',
    image: '/images/solutions/solution-3.png',
    info: 'نسعى إلى تحقيق تحول شامل في مجال إدارة الموارد البشرية، من خلال حلول مبتكرة لتنظيم وإدارة جميع جوانب الموارد البشرية بكفاءة وفعالية، بهدف تعزيز أداء الموظفين وتعزيز النجاح المؤسسي.',
    extraInfo: (
      <div className='describe-content'>
        <p className='para'>
          نسعى إلى تحقيق تحول شامل في مجال إدارة الموارد البشرية، من خلال حلول
          مبتكرة لتنظيم وإدارة جميع جوانب الموارد البشرية بكفاءة وفعالية، بهدف
          تعزيز أداء الموظفين وتعزيز النجاح المؤسسي , الدور الذي تقوم به أنظمة
          HR في الشركات:
        </p>

        <div className='row g-md-5'>
          <div className='col-md-5'>
            <ul className='describe-list'>
              <li>
                <strong> إدارة بيانات الموظفين </strong>
              </li>
              <li>
                <strong> إدارة عمليات التوظيف </strong>
              </li>
              <li>
                <strong> إدارة الأداء والتقييم</strong>
              </li>
              <li>
                <strong> إدارة الرواتب والمزايا </strong>
              </li>
              <li>
                <strong> إدارة الحضور والانصراف </strong>
              </li>
            </ul>
          </div>

          <div className='col-md-5'>
            <ul className='describe-list'>
              <li>
                <strong> تطوير الموظفين والتدريب </strong>
              </li>
              <li>
                <strong> إدارة العلاقات الداخلية </strong>
              </li>
              <li>
                <strong>تحليلات وتقارير الموارد البشرية </strong>
              </li>
              <li>
                <strong>الإمتثال والتوافق </strong>
              </li>
            </ul>
          </div>
        </div>

        <p className='para mt-3'> مميزات استخدام أنظمة HR في الشركات:- </p>

        <ul className='describe-list'>
          <li>
            <strong> زيادة الكفاءة </strong> : أتمتة العديد من العمليات
            الروتينية مما يوفر الوقت والجهد.
          </li>
          <li>
            <strong> تحسين دقة البيانات </strong> : تقليل الأخطاء البشرية وزيادة
            دقة البيانات المتعلقة بالموظفين.
          </li>
          <li>
            <strong> تحسين تجربة الموظفين </strong> : توفير واجهة سهلة الاستخدام
            لتحسين تفاعل الموظفين مع الإدارة.
          </li>
          <li>
            <strong> اتخاذ قرارات مستنيرة </strong> : تقديم تقارير وتحليلات
            تساعد الإدارة في اتخاذ قرارات استراتيجية.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 'dynamic',
    title: 'Dynamic Customization',
    image: '/images/solutions/solution-1.png',
    info: 'نعمل على تخصيص و تطوير البرامج لتلبية احتياجات العملاء في مختلف الصناعات والقطاعات. حيث نقوم بتحليل متطلبات الشركات بعناية لتعديل البرنامج بما يتوافق مع متطلباتهم الفريدة.',
    extraInfo: (
      <div className='describe-content'>
        <p className='para'>
          نعمل على تخصيص و تطوير البرامج لتلبية احتياجات العملاء المتنوعة في
          مختلف الصناعات والقطاعات. حيث نقوم بتحليل متطلبات الشركات بعناية
          لتعديل البرنامج بما يتوافق مع متطلباتهم الفريدة، سواءً كان ذلك بإضافة
          وظائف جديدة أو تعديل الوظائف الحالية أو تخصيص واجهة المستخدم. يعتمد
          عملنا على المهارات العميقة في مجال البرمجة والتواصل الفعّال مع العملاء
          لضمان تحقيق أهدافهم بأقصى قدر من الكفاءة.
        </p>
      </div>
    ),
  },
];

const SPONSOR = [
  '/images/sponsor/sponsor-1.png',
  '/images/sponsor/sponsor-2.png',
  '/images/sponsor/sponsor-3.png',
  '/images/sponsor/sponsor-4.png',
  '/images/sponsor/sponsor-5.png',
  '/images/sponsor/sponsor-6.png',
  '/images/sponsor/sponsor-7.png',
  '/images/sponsor/sponsor-86.png',
  '/images/sponsor/sponsor-8.png',
  '/images/sponsor/sponsor-9.png',
  '/images/sponsor/sponsor-10.png',
  '/images/sponsor/sponsor-11.png',
  '/images/sponsor/sponsor-12.png',
  '/images/sponsor/sponsor-13.png',
  '/images/sponsor/sponsor-14.png',
  '/images/sponsor/sponsor-15.png',
  '/images/sponsor/sponsor-16.png',
  '/images/sponsor/sponsor-17.png',
  '/images/sponsor/sponsor-18.png',
  '/images/sponsor/sponsor-19.png',
  '/images/sponsor/sponsor-20.png',
  '/images/sponsor/sponsor-21.png',
  '/images/sponsor/sponsor-22.png',
  '/images/sponsor/sponsor-23.png',
  '/images/sponsor/sponsor-24.png',
  '/images/sponsor/sponsor-25.png',
  '/images/sponsor/sponsor-26.png',
  '/images/sponsor/sponsor-27.png',
  '/images/sponsor/sponsor-28.png',
  '/images/sponsor/sponsor-29.png',
  '/images/sponsor/sponsor-30.png',
  '/images/sponsor/sponsor-31.png',
  '/images/sponsor/sponsor-32.png',
  '/images/sponsor/sponsor-33.png',
  '/images/sponsor/sponsor-34.png',
  '/images/sponsor/sponsor-35.png',
  '/images/sponsor/sponsor-36.png',
  '/images/sponsor/sponsor-37.png',
  '/images/sponsor/sponsor-38.png',
  '/images/sponsor/sponsor-39.png',
  '/images/sponsor/sponsor-40.png',
  '/images/sponsor/sponsor-41.png',
  '/images/sponsor/sponsor-42.png',
  '/images/sponsor/sponsor-43.png',
  '/images/sponsor/sponsor-44.png',
  '/images/sponsor/sponsor-45.png',
  '/images/sponsor/sponsor-46.png',
  '/images/sponsor/sponsor-47.png',
  '/images/sponsor/sponsor-48.png',
  '/images/sponsor/sponsor-49.png',
  '/images/sponsor/sponsor-50.png',
  '/images/sponsor/sponsor-51.png',
  '/images/sponsor/sponsor-52.png',
  '/images/sponsor/sponsor-53.png',
  '/images/sponsor/sponsor-54.png',
  '/images/sponsor/sponsor-55.png',
  '/images/sponsor/sponsor-56.png',
  '/images/sponsor/sponsor-57.png',
  '/images/sponsor/sponsor-58.png',
  '/images/sponsor/sponsor-59.png',
  '/images/sponsor/sponsor-60.png',
  '/images/sponsor/sponsor-61.png',
  '/images/sponsor/sponsor-62.png',
  '/images/sponsor/sponsor-63.png',
  '/images/sponsor/sponsor-64.png',
  '/images/sponsor/sponsor-65.png',
  '/images/sponsor/sponsor-66.png',
  '/images/sponsor/sponsor-67.png',
  '/images/sponsor/sponsor-68.png',
  '/images/sponsor/sponsor-69.png',
  '/images/sponsor/sponsor-70.png',
  '/images/sponsor/sponsor-71.png',
  '/images/sponsor/sponsor-72.png',
  '/images/sponsor/sponsor-73.png',
  '/images/sponsor/sponsor-74.png',
  '/images/sponsor/sponsor-75.png',
  '/images/sponsor/sponsor-76.png',
  '/images/sponsor/sponsor-77.png',
  '/images/sponsor/sponsor-78.png',
  '/images/sponsor/sponsor-79.png',
  '/images/sponsor/sponsor-80.png',
  '/images/sponsor/sponsor-81.png',
  '/images/sponsor/sponsor-82.png',
  '/images/sponsor/sponsor-83.png',
  '/images/sponsor/sponsor-84.png',
  '/images/sponsor/sponsor-85.png',
];

const HOMEPAGE_SPONSOR = [
  '/images/sponsor/sponsor-1.png',
  '/images/sponsor/sponsor-2.png',
  '/images/sponsor/sponsor-3.png',
  '/images/sponsor/sponsor-4.png',
  '/images/sponsor/sponsor-86.png',
  '/images/sponsor/sponsor-6.png',
  '/images/sponsor/sponsor-7.png',
  '/images/sponsor/sponsor-8.png',
  '/images/sponsor/sponsor-9.png',
  '/images/sponsor/sponsor-10.png',
  '/images/sponsor/sponsor-11.png',
  '/images/sponsor/sponsor-12.png',
  '/images/sponsor/sponsor-13.png',
  '/images/sponsor/sponsor-14.png',
  '/images/sponsor/sponsor-15.png',
  '/images/sponsor/sponsor-16.png',
  '/images/sponsor/sponsor-17.png',
  '/images/sponsor/sponsor-18.png',
  '/images/sponsor/sponsor-19.png',
  '/images/sponsor/sponsor-5.png',
];

const TESTIMONIAL = [
  {
    id: '1',
    name: 'Engy Khaled - HR MANAGER   JIT',
    image: '/images/testimonial/jit.jpeg',
    job: '',
    description: (
      <>
        Almost number one for Business solutions at Middle East we are so proud
        for our business together Also, special appreciation for your amazing
        Team members
        <br />
        Mr. Ahmed El Tokhay numbers as an HR Consultant on software ,Mrs. Marina
        Atef supportive and smart technical HR ,Mr. Mahmoud ossman best sales
        manager all the way , Mr. Youssef talent developer
        <br />
        And finally, one of the best Mr. Mohamed Wessam CEO
        <br />
        Really wishing you all the success Dynamic Business Solution keep up the
        great work and keep doing your best
      </>
    ),
    dir: 'left',
  },
  {
    id: '2',
    name: 'محمد صبحي - مدير ادارة تكنولوجيا المعلومات',
    image: '/images/testimonial/new-modern.png',
    job: 'شركة مودرن بلاست - نيو مودرن بلاست',
    description: (
      <>
        في البدايه أقدم لكم أجمل عبارات الشكر والاحترام والتقدير لشركتكم الموقره
        علي الدعم الكامل الاداري والفني لادارة برنامج NEXT HR . <br />
        واختص بالشكر المهندسه / سهيله على الجهد الكبير والاهتمام الواضح حتي
        اتمام البرنامج واستخراج المرتبات لشركتنا ( مودرن بلاست - نيو مودرن بلاست
        ) . لكم من جزيل الشكر والاحترام , دائما في تقدم ونجاح ان شاء الله .
      </>
    ),
  },
];

const SOLUTION_OPTIONS = [
  {
    value: 'ERPNext',
    label: 'ERPNext',
  },
  {
    value: 'Nova HR',
    label: 'Nova HR',
  },
  {
    value: 'Car System',
    label: 'Car System',
  },
  {
    value: 'Shipping System',
    label: 'Shipping System',
  },
  {
    value: 'Mobile App',
    label: 'Mobile App',
  },
  {
    value: 'Healthcare',
    label: 'Healthcare',
  },
];

const SEO_PAGES = {
  homepage: {
    title: 'الصفحة الرئيسية',
    description:
      'شركة متخصصة في تقديم أحدث الحلول التقنية والأكثر تطورًا للشركات أنظمة ماليه و إدارة ERPNext لدينا الخبرة والموارد لمساعدة الشركات من جميع الأحجام على تحقيق أهدافها والوصول إلى آفاق جديدة من خلال حلولنا ',
  },
  erpnext: {
    title: 'ERPNext',
    description:
      'ERPNext نظام متكامل لإدارة الحسابات و المشتريات و المبيعات و المخازن و الموارد البشرية و التصنيع والإنتاج و المقاولات بكل سهولة وبأفضل سعر احصل على طلب عرض تجريبي مجانا.',
  },
  novaHr: {
    title: 'Nova HR',
    description:
      'نوفا اتش ار نظام لادارة جميع جوانب الموارد البشرية بكفاءة وفعالية، من إدارة بيانات الموظفين والحضور والانصراف والرواتب والتوظيف إلى تحسين الأداء والتدريب. تعرف على المزيد الان!',
  },
  dynamicCustomization: {
    title: 'Dynamic Customization',
    description:
      'نعمل على تخصيص وتطوير البرامج لتلبية احتياجات العملاء المتنوعة في مختلف الصناعات والقطاعات.',
  },
  aboutUs: {
    title: 'من نحن',
    description:
      'شركة متخصصة في تقديم أحدث الحلول التقنية والأكثر تطورًا للشركات. لدينا الخبرة والموارد لمساعدة الشركات من جميع الأحجام على تحقيق أهدافها والوصول إلى آفاق جديدة. الشريك المعتمد لـ Frappe، الشركة المطورة لـ ERPNext، الذي يُعَد من أبرز الأنظمة لإدارة موارد المؤسسة في العالم. بفضل هذه الشراكة المميزة، نقدم لعملائنا حلول ERPNext المتطورة التي تساعدهم على تحقيق أعلى مستويات الكفاءة وتحسين إدارة مواردهم',
  },
  solutions: {
    title: 'الحلول',
    description:
      'يوفر نظام ERPNext إدارة ومتابعة كاملة لأقسام الشركة: إدارة الحسابات إدارة المبيعات إدارة المشتريات إدارة المخازن إدارة العملاء والموردين إدارة الأصول إدارة الإنتاج والتصنيع إدارة المشاريع والمقاولات إدارة الموارد البشرية بكل سهولة وبأفضل سعر احصل على طلب عرض تجريبي مجانا.',
  },
  contactUs: {
    title: 'اتصل بنا',
    description:
      'جرّب الآن حلول ديناميك ERP System HR System,واستمتع بتجربة لا مثيل لها.',
  },
  clients: {
    title: 'العملاء',
    description: 'موثوق بنا من قبل اكثر من 250 عميل على مستوى الشرق الاوسط',
  },
  error: {
    title: 'خطأ',
    description: 'عذرا لا يمكن الوصول للصفحة المطلوبة',
  },
  privacyAndPolicy: {
    title: 'سياسة الخصوصية',
    description: 'سياسة الخصوصية',
  },
  termsAndConditions: {
    title: 'الشروط والاحكام',
    description: 'الشروط والاحكام',
  },
};

export {
  DYNAMIC_MODULES,
  ERPNEXT_MODULES,
  HOMEPAGE_SPONSOR,
  NOVA_HR_MODULES,
  SEO_PAGES,
  SERVICES,
  SOLUTIONS,
  SOLUTION_OPTIONS,
  SPONSOR,
  STATUS,
  TESTIMONIAL,
  WHY_CHOOSE_US,
};
