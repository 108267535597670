import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../utils';

function SolutionCard({ item }) {
  return (
    <div
      className='eg-card auction-card3 wow fadeInDown'
      data-wow-duration='1.5s'
      data-wow-delay='0.2s'
    >
      <div className='auction-img'>
        <Link to={`/solution-details/${item.id}`} onClick={scrollToTop}>
          <img alt={item.title} src={item.image} />
        </Link>
      </div>
      <div className='auction-content'>
        <h4>
          <Link to={`/solution-details/${item.id}`} onClick={scrollToTop}>
            {item.title}
          </Link>
        </h4>

        <div>{item.info}</div>
      </div>
    </div>
  );
}

SolutionCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    info: PropTypes.node.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

export default SolutionCard;
