import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { HOMEPAGE_SPONSOR } from '../../core/data';

function SponsorArea() {
  const { t } = useTranslation();

  const SponsorAreaSlide = {
    rows: 1,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='sponsor-section style-3'>
      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6'>
            <div className='section-title1'>
              <h2>
                {t('trusted-by-more-than-250-active-clients-middle-east')}
              </h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div
            className='slick-wrapper wow fadeInUp'
            data-wow-duration='1.5s'
            data-wow-delay='0.2s'
          >
            <Slider {...SponsorAreaSlide}>
              {HOMEPAGE_SPONSOR.map((item) => (
                <div className='slide-item' key={item}>
                  <img alt='sponsorImage' src={item} width={160} height={90} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SponsorArea;
