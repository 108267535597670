import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  MdAlternateEmail,
  MdLocationPin,
  MdOutlinePhone,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import CONFIG from '../core/config';
import { initPhoneNumber, scrollToTop } from '../utils';


function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className='footer-top'>
        <div className='container'>
          <div className='row gy-5'>
            <div className='col-md-4'>
              <div className='footer-item'>
                <Link to='/' onClick={scrollToTop}>
                  <img
                    alt='images'
                    width={180}
                    src='/images/favicons/favicon.png'
                  />
                </Link>

                <p>
                  نركز جهودنا على تقديم حلول متطورة تتماشى مع متطلبات الشركات
                  السعودية وتطابق أنظمة العمل من خلال التزامنا بالابتكار
                  والتطوير و تزويد الشركات بنظام متكامل وفعال لإدارة العمليات و
                  زيادة الانتاجية
                </p>

                <div className='footer-social'>
                  <a
                    href={CONFIG.facebookUrl}
                    title='facebook'
                    rel='noreferrer'
                    target='_blank'
                  >
                    <i className='bi bi-facebook'></i>
                  </a>

                  <a
                    href={CONFIG.linkedInUrl}
                    title='linkedin'
                    rel='noreferrer'
                    target='_blank'
                  >
                    <i className='bi bi-linkedin'></i>
                  </a>

                  <a
                    href={CONFIG.twitterUrl}
                    title='twitter'
                    rel='noreferrer'
                    target='_blank'
                  >
                    <i className='bi bi-twitter'></i>
                  </a>
                </div>

                
              </div>
            </div>

            <div className='col-md-4 d-flex justify-content-lg-center'>
              <div className='footer-item'>
                <h5 className=''> الفروع </h5>

                <h6 className='text-white'> المملكة العربية السعودية </h6>
                <div className='mb-2 d-flex gap-2 text-white'>
                  <div>
                    <MdLocationPin style={{ fontSize: 25 }} />
                  </div>
                  <p className='mb-0'> {CONFIG.address} </p>
                </div>

                <div className='mb-1 d-flex gap-2 text-white'>
                  <div>
                    <MdAlternateEmail style={{ fontSize: 25 }} />
                  </div>
                  <a
                    href={`mailto:${CONFIG.email}`}
                    rel='noreferrer'
                    target='_blank'
                  >
                    {CONFIG.email}
                  </a>
                </div>

      

                <div className='d-flex gap-2 text-white'>
                  <div>
                    <MdOutlinePhone style={{ fontSize: 25 }} />
                  </div>
                  <a
                    href={`tel:${initPhoneNumber(CONFIG.phone)}`}
                    dir='ltr'
                    rel='noreferrer'
                    target='_blank'
                  >
                    {CONFIG.phone}
                  </a>
                </div>

                <h6 className='text-white mt-5'> جمهورية مصر العربية </h6>
                <div className='mb-2 d-flex gap-2 text-white'>
                  <div>
                    <MdLocationPin style={{ fontSize: 25 }} />
                  </div>
                  <p className='mb-0'> {CONFIG.egyptAddress} </p>
                </div>

                <div className='mb-1 d-flex gap-2 text-white'>
                  <div>
                    <MdAlternateEmail style={{ fontSize: 25 }} />
                  </div>
                  <a
                    href={`mailto:${CONFIG.egyptEmail}`}
                    rel='noreferrer'
                    target='_blank'
                  >
                    {CONFIG.egyptEmail}
                  </a>
                </div>

                <div className='d-flex gap-2 text-white'>
                  <div>
                    <MdOutlinePhone style={{ fontSize: 25 }} />
                  </div>
                  <a
                    href={`tel:${initPhoneNumber(CONFIG.egyptPhone)}`}
                    dir='ltr'
                    rel='noreferrer'
                    target='_blank'
                  >
                    {CONFIG.egyptPhone}
                  </a>
                </div>
              </div>
            </div>

            <div className='col-md-2 d-flex justify-content-lg-center'>
              <div className='footer-item'>
                <h5>{t('navigation')}</h5>
                <ul className='footer-list'>
                  <li>
                    <Link onClick={scrollToTop} to='/about'>
                      {t('about-dynamic')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to='/clients'>
                      {t('clients')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to='/solutions'>
                      {t('our-solutions')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className=' col-md-2 d-flex justify-content-lg-center'>
              <div className='footer-item'>
                <h5>{t('help-and-faqs')}</h5>
                <ul className='footer-list'>
                  <li>
                    <Link onClick={scrollToTop} to='/be-our-partner'>
                      {t('be-our-partner')}
                    </Link>
                  </li>
                  <li>
                    <Link to='/terms-conditions' onClick={scrollToTop}>
                      {t('terms-and-conditions')}
                    </Link>
                  </li>
                  <li>
                    <Link to='privacy-policy' onClick={scrollToTop}>
                      {t('privacy-add-policy')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to='/contact'>
                      {t('contact-us')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-bottom'>
        <div className='container'>
          <div className='d-flex justify-content-center'>
            <p>{t('c-copyright-dynamic-business-solution-2024')}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
