import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import aboutImage from '../assets/images/background/about.png';
import heroImage from '../assets/images/background/bg-hero-image.png';
import frappeCertificateImage from '../assets/images/background/frappe-certificate.png';
import certificateImage from '../assets/images/icons/certificate.png';
import AboutUsCounter from '../components/common/AboutUsCounter';
import SEOHelmet from '../components/common/SEOHelmet';
import Services from '../components/common/Services';
import Solutions from '../components/common/Solutions';
import SponsorArea from '../components/common/SponsorArea';
import Testimonial from '../components/common/Testimonial';
import { scrollToTop } from '../utils';
import ContactForm from '../components/common/ContactForm';

function HomePage3() {
  const { t } = useTranslation();

  return (
    <>
      <SEOHelmet pageKey='homepage' />

      <div className='hero-area hero-style-three'>
        <img
          alt='bannerImage'
          src={heroImage}
          className='home3-banner img-fluid'
        />

        <div className='container-lg container-fluid'>
          <div className='row d-flex justify-content-start align-items-end'>
            <div className='col-xl-6 col-lg-5 px-0'>
              <div className='banner3-content'>
                <h1
                  className='wow fadeInDown'
                  data-wow-duration='1.5s'
                  data-wow-delay='1s'
                >
                  حلول التكنولوجيا المبتكرة
                </h1>

                <span
                  className='wow fadeInDown text-gray mb-5'
                  data-wow-duration='1.5s'
                  data-wow-delay='0.5s'
                >
                  نركز جهودنا على تقديم حلول متطورة تتماشى مع متطلبات الشركات
                  السعودية وتطابق أنظمة العمل من خلال التزامنا بالابتكار
                  والتطوير و تزويد الشركات بنظام متكامل وفعال لإدارة العمليات و
                  زيادة الانتاجية
                </span>

                <Link
                  to='/contact#contact'
                  className='eg-btn btn--primary3 btn--lg wow fadeInUp d-md-none'
                  data-wow-duration='2s'
                  data-wow-delay='0.8s'
                >
                  طلب عرض تجريبي مجاني
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='my-5'>
        <AboutUsCounter />
      </div>

      <div className='about-section pt-5 mt-5 overflow-hidden'>
        <div className='container'>
          <div className='row align-items-center g-4'>
            <div className='col-lg-7'>
              <div
                className='about-content wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.2s'
              >
                <h2>عن ديناميك</h2>
                <p className='para text-dark'>
                  ديناميك بيزنس سوليوشن للبرمجيات شركة متخصصة في تقديم أحدث
                  الحلول التقنية والأكثر تطورًا للشركات. حيث نقدم مجموعة واسعة
                  من البرامج الإدارية وحلول تخطيط موارد المؤسسات التي تلبي
                  احتياجات مختلف القطاعات والصناعات., لدينا الخبرة والموارد
                  لمساعدة الشركات من جميع الأحجام على تحقيق أهدافها والوصول إلى
                  آفاق جديدة.
                </p>

                <Link
                  to='/about'
                  onClick={scrollToTop}
                  className='eg-btn btn--primary3 btn--lg wow fadeInUp'
                  data-wow-duration='2s'
                  data-wow-delay='0.8s'
                >
                  اقرأ المزيد
                </Link>
              </div>
            </div>

            <div className='col-lg-5'>
              <div className='about-img-area'>
                <img
                  src={aboutImage}
                  className='img-fluid about-img wow fadeInUp'
                  data-wow-duration='1.5s'
                  data-wow-delay='.2s'
                  alt='about-img'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='about-section overflow-hidden pt-120 certificate-section '>
        <div className='container pb-60'>
          <div className='row d-flex justify-content-center align-items-center g-4'>
            <div className='col-md-6'>
              <div className='d-flex justify-content-center mb-3'>
                <img
                  src={certificateImage}
                  alt='certificate'
                  width={64}
                  height={64}
                />
              </div>

              <div className='text-center'>
                <h2>شريك معتمد لـ Frappe (ERPNext)</h2>
                <h3>Official Certified Partner ERPNext</h3>

                <p className='para text-dark mt-4'>
                  في شركة ديناميك، نعتز بأن نكون الشريك المعتمد لـ Frappe،
                  الشركة المطورة لـ ERPNext، الذي يُعَد من أبرز الأنظمة لإدارة
                  موارد المؤسسة في العالم. بفضل هذه الشراكة المميزة، نقدم
                  لعملائنا حلول ERPNext المتطورة التي تساعدهم على تحقيق أعلى
                  مستويات الكفاءة وتحسين إدارة مواردهم بفعالية.
                </p>

                <div
                  className='sidebar-widget-title mb-2 mt-5 wow fadeInDown'
                  data-wow-duration='1.5s'
                  data-wow-delay='.5s'
                >
                  <h5> خدماتنا كشريك </h5>
                </div>

                <p
                  className='para wow fadeInDown'
                  data-wow-duration='1.5s'
                  data-wow-delay='.5s'
                >
                  تنفيذ و تركيب النظام - التدريب و الدعم - التخصيص و التطوير
                </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-img-area'>
                <img
                  src={frappeCertificateImage}
                  className='img-fluid about-img wow fadeInUp'
                  data-wow-duration='1.5s'
                  data-wow-delay='.2s'
                  alt='about-img'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Solutions />

      <div className='live-auction mt-50 pb-60'>
        <div className='container position-relative'>
          <div className='row d-flex justify-content-center'>
            <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6'>
              <div className='section-title4'>
                <h2>{t('our-services')}</h2>
                <p className='mb-0'>
                  {t(
                    'we-will-help-you-to-optimize-the-running-costs-of-your-business'
                  )}
                </p>
              </div>
            </div>
          </div>

          <Services />
        </div>
      </div>

      <SponsorArea />

      <Testimonial />
    </>
  );
}

export default HomePage3;
