import React from 'react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SERVICES } from '../../core/data';

SwiperCore.use([Autoplay, Navigation]);

function Services() {
  const categorySlider = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 30,
    loop: true,
    autoplay: true,
    roundLengths: true,
    navigation: {
      nextEl: '.category-prev1',
      prevEl: '.category-next1',
    },

    breakpoints: {
      280: {
        slidesPerView: 1,
      },
      440: {
        slidesPerView: 2,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      },
      1400: {
        slidesPerView: 7,
      },
    },
  };

  return (
    <div className='category-section'>
      <div className='container position-relative'>
        <div className='row d-flex justify-content-center'>
          <Swiper
            {...categorySlider}
            className='swiper category1-slider'
            style={{ width: '100%' }}
          >
            <div className='swiper-wrapper'>
              {SERVICES.map((item, index) => (
                <SwiperSlide className='swiper-slide' key={item.id}>
                  <div
                    className='eg-card category-card1 style2 wow fadeInDown'
                    data-wow-duration='1.5s'
                    data-wow-delay={`${(0.2 * index) % 3}s`}
                  >
                    <div className='cat-icon'>
                      <img src={item.image} alt={item.title} />
                    </div>
                    <div>
                      <h5>{item.title} </h5>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>

        <div className='slider-arrows text-center d-lg-flex d-none  justify-content-end'>
          <div
            className='category-prev1 style2 swiper-prev-arrow'
            tabIndex={0}
            role='button'
            aria-label='Previous slide'
          >
            <i className='bx bx-chevron-left' />
          </div>
          <div
            className='category-next1 style2 swiper-next-arrow'
            tabIndex={0}
            role='button'
            aria-label='Next slide'
          >
            <i className='bx bx-chevron-right' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
