import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import WOW from 'wowjs';
import Footer from './Footer';
import Header from './Header';

function BaseLayout(props) {
  const { children } = props;

  const location = useLocation();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, [location.pathname]);

  return (
    <>
      <Link to='/contact#contact' className='eg-btn btn--primary3 btn--lg fixed-action'>
        طلب عرض تجريبي مجاني
      </Link>

      <Header />
      {children}
      <Footer />
    </>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
