import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import SEOHelmet from '../components/common/SEOHelmet';
import Breadcrumb from '../components/common/Breadcrumb';
import sendSuccessImage from '../assets/images/background/send-success.svg'

function Success() {
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      history.replace('/');
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
     <SEOHelmet pageKey='error' />

      <Breadcrumb pageName={t('sended-success')} pageTitle={t('sended-success')} />

      <div className='error-section pt-120 pb-120'>
        <img
          src='/images/bg/e-vector1.svg'
          className='evector1'
          alt='evector1'
        />
        <img
          src='/images/bg/e-vector2.svg'
          className='evector2'
          alt='evector2'
        />
        <img
          src='/images/bg/e-vector3.svg'
          className='evector3'
          alt='evector3'
        />
        <img
          src='/images/bg/e-vector4.svg'
          className='evector4'
          alt='evector4'
        />

        <div className='container'>
          <div className='row d-flex justify-content-center g-4'>
            <div className='col-lg-5 col-md-7 text-center'>
              <div className='error-wrapper'>
                <img
                  src={sendSuccessImage}
                  className='error-bg img-fluid'
                  alt='error-bg'
                />

                <div
                  className='error-content wow fadeInDown'
                  data-wow-duration='1.5s'
                  data-wow-delay='.2s'
                >
                  <h2>{t('thank-you-we-received-your-message')}</h2>
             

           
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Success;
