import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/common/Breadcrumb';
import ContactForm from '../components/common/ContactForm';
import SEOHelmet from '../components/common/SEOHelmet';
import SponsorArea from '../components/common/SponsorArea';

function Contact() {
  const { t } = useTranslation();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const yOffset = -150; // Offset by 150px
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <>
      <SEOHelmet pageKey='contactUs' />

      <Breadcrumb pageName={t('contact-us')} pageTitle={t('contact-us')} />

      <div className='mt-5' id='contact'>
        <ContactForm />
      </div>

      <SponsorArea />
    </>
  );
}

export default Contact;
