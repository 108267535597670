import React from 'react';
import aboutImage from '../../assets/images/background/about.png';

function WhoWeAre() {
  return (
    <div className='about-section pt-120 overflow-hidden'>
      <div className='container'>
        <div className='row d-flex justify-content-center g-4'>
          <div className='col-lg-7'>
            <div
              className='about-content wow fadeInDown'
              data-wow-duration='1.5s'
              data-wow-delay='.2s'
            >
              <h2>عن ديناميك</h2>
              <p className='para'>
                ديناميك بيزنس سوليوشن للبرمجيات شركة متخصصة في تقديم أحدث الحلول
                التقنية والأكثر تطورًا للشركات. حيث نقدم مجموعة واسعة من البرامج
                الإدارية وحلول تخطيط موارد المؤسسات التي تلبي احتياجات مختلف
                القطاعات والصناعات., لدينا الخبرة والموارد لمساعدة الشركات من
                جميع الأحجام على تحقيق أهدافها والوصول إلى آفاق جديدة.
              </p>

              <div
                className='sidebar-widget-title mb-2 mt-5 wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.5s'
              >
                <h5> أهدافنا</h5>
                <span></span>
              </div>

              <p
                className='para wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.5s'
              >
                تسعى شركتنا إلى تحقيق تميزها كمزود رئيسي للحلول التقنية المبتكرة
                في الشرق الأوسط، من خلال تقديم خدمات برمجية متطورة تلبي احتياجات
                الشركات وتسهم في تحقيق أهدافها التشغيلية والاستراتيجية بكفاءة
                وفعالية عالية.
              </p>

              <div
                className='sidebar-widget-title mb-2 mt-2 wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.8s'
              >
                <h5> مهمتنا</h5>
                <span></span>
              </div>
              <p
                className='para wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.8s'
              >
                تلبية متطلبات الأعمال لعملائنا من خلال حلولنا المبتكرة تتجاوز
                التوقعات ..
              </p>

              <div
                className='sidebar-widget-title mb-2 mt-2 wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='1.2s'
              >
                <h5> رؤيتنا</h5>
                <span></span>
              </div>
              <p
                className='para wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='1.2s'
              >
                أن نكون الشركة الرائدة في تقديم حلول التكنولوجيا المبتكرة في
                منطقة الشرق الأوسط، وأن نمكّن الشركات من تحقيق أهدافها من خلال
                خدماتنا البرمجية المتقدمة.
              </p>

              <div
                className='tab-pane fade show active'
                id='v-pills-dashboard'
                role='tabpanel'
                aria-labelledby='v-pills-dashboard-tab'
              ></div>
            </div>
          </div>

          <div className='col-lg-5'>
            <div className='about-img-area'>
              <img
                src={aboutImage}
                className='img-fluid about-img wow fadeInUp'
                data-wow-duration='1.5s'
                data-wow-delay='.2s'
                alt='about-img'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;
