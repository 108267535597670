import React from 'react';
import { useTranslation } from 'react-i18next';
import { SOLUTIONS } from '../../core/data';
import SolutionCard from './SolutionCard';

function Solutions() {
  const { t } = useTranslation();

  return (
    <div className='live-auction pb-120 pt-120'>
      <div className='container position-relative'>
        <div className='row d-flex justify-content-center'>
          <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6'>
            <div className='section-title4'>
              <h2>{t('our-solutions')}</h2>
              <p className='mb-0'>قيادة اعمالك من خلال حلول رقمية متطورة</p>
            </div>
          </div>
        </div>

        <div className='row gy-4 d-flex justify-content-center'>
          {SOLUTIONS.map((item) => (
            <div className='col-lg-4 col-md-6 col-sm-10 ' key={item.id}>
              <SolutionCard item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Solutions;
