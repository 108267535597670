import React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/common/Breadcrumb';
import SEOHelmet from '../components/common/SEOHelmet';

function Faq() {
  const { t } = useTranslation();

  return (
    <>
      <SEOHelmet pageKey='privacyAndPolicy' />

      <Breadcrumb
        pageName={t('privacy-and-policy')}
        pageTitle={t('privacy-and-policy')}
      />
      <p className='text-start'></p>

      <div className='container pt-120 pb-120'>
        <div
          className='faq-wrap wow fadeInUp'
          data-wow-duration='1.5s'
          data-wow-delay='.2s'
        >
          <div className='accordion' id='accordionExample'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingOne'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseOne'
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  Privacy Policy for Dynamic
                </button>
              </h2>
              <div
                id='collapseOne'
                className='accordion-collapse collapse'
                aria-labelledby='headingOne'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    At Dynamic, accessible from{' '}
                    <a href='https://dynamiceg.com'>https://dynamiceg.com</a>,
                    one of our main priorities is the privacy of our visitors.
                    This Privacy Policy document contains types of information
                    that is collected and recorded by Dynamic and how we use it.
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us.
                    This Privacy Policy applies only to our online activities
                    and is valid for visitors to our website with regards to the
                    information that they shared and/or collect in Dynamic. This
                    policy is not applicable to any information collected
                    offline or via channels other than this website.
                  </p>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingTwo'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseTwo'
                  aria-expanded='false'
                  aria-controls='collapseTwo'
                >
                  Consent
                </button>
              </h2>
              <div
                id='collapseTwo'
                className='accordion-collapse collapse'
                aria-labelledby='headingTwo'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    By using our website, you hereby consent to our Privacy
                    Policy and agree to its terms.
                  </p>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingThree'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseThree'
                  aria-expanded='false'
                  aria-controls='collapseThree'
                >
                  Information we collect
                </button>
              </h2>
              <div
                id='collapseThree'
                className='accordion-collapse collapse'
                aria-labelledby='headingThree'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    The personal information that you are asked to provide, and
                    the reasons why you are asked to provide it, will be made
                    clear to you at the point we ask you to provide your
                    personal information. If you contact us directly, we may
                    receive additional information about you such as your name,
                    email address, phone number, the contents of the message
                    and/or attachments you may send us, and any other
                    information you may choose to provide. When you register for
                    an Account, we may ask for your contact information,
                    including items such as name, company name, address, email
                    address, and telephone number.
                  </p>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingFour'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseFour'
                  aria-expanded='false'
                  aria-controls='collapseFour'
                >
                  How we use your information
                </button>
              </h2>
              <div
                id='collapseFour'
                className='accordion-collapse collapse'
                aria-labelledby='headingFour'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    We use the information we collect in various ways, including
                    to:
                    <br />
                    Provide, operate, and maintain our website <br />
                    Improve, personalize, and expand our website <br />
                    Understand and analyze how you use our website <br />
                    Develop new products, services, features, and functionality{' '}
                    <br />
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the website,
                    and for marketing and promotional purposes <br />
                    Send you emails <br />
                    Find and prevent fraud
                  </p>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingFive'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseFive'
                  aria-expanded='false'
                  aria-controls='collapseFive'
                >
                  Log Files
                </button>
              </h2>
              <div
                id='collapseFive'
                className='accordion-collapse collapse'
                aria-labelledby='headingFive'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    Dynamic follows a standard procedure of using log files.
                    These files log visitors when they visit websites. All
                    hosting companies do this and a part of hosting services'
                    analytics. The information collected by log files include
                    internet protocol (IP) addresses, browser type, Internet
                    Service Provider (ISP), date and time stamp, referring/exit
                    pages, and possibly the number of clicks. These are not
                    linked to any information that is personally identifiable.
                    The purpose of the information is for analyzing trends,
                    administering the site, tracking users' movement on the
                    website, and gathering demographic information.
                  </p>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingSix'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseSix'
                  aria-expanded='false'
                  aria-controls='collapseSix'
                >
                  Cookies and Web Beacons
                </button>
              </h2>
              <div
                id='collapseSix'
                className='accordion-collapse collapse'
                aria-labelledby='headingSix'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p>
                    Like any other website, Dynamic uses 'cookies'. These
                    cookies are used to store information including visitors'
                    preferences, and the pages on the website that the visitor
                    accessed or visited. The information is used to optimize the
                    users' experience by customizing our web page content based
                    on visitors' browser type and/or other information.
                  </p>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingSeven'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseSeven'
                  aria-expanded='false'
                  aria-controls='collapseSeven'
                >
                  Surveys
                </button>
              </h2>
              <div
                id='collapseSeven'
                className='accordion-collapse collapse'
                aria-labelledby='headingSeven'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p>
                    We may also from time to time provide you with surveys,
                    which are designed to measure the interests of our users.
                    The information that we collect through surveys will be used
                    for the purpose of improving the website, improving your
                    experience on the website, and providing you with more
                    relevant advertising.
                  </p>
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='heading-8'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapse-8'
                  aria-expanded='false'
                  aria-controls='collapse-8'
                >
                  Information Collection and Use
                </button>
              </h2>
              <div
                id='collapse-8'
                className='accordion-collapse collapse'
                aria-labelledby='heading-8'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    For a better experience, we recommend that you use a recent,
                    browser-upgradable web browser. You are responsible for
                    protecting your own computer and your data from loss and
                    theft, and we cannot guarantee that your data will not be
                    accessed, modified, or disclosed in ways beyond our control.
                  </p>
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='heading-9'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapse-9'
                  aria-expanded='false'
                  aria-controls='collapse-9'
                >
                  Security of Your Data
                </button>
              </h2>
              <div
                id='collapse-9'
                className='accordion-collapse collapse'
                aria-labelledby='heading-9'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    We take precautions to protect your data from loss or theft,
                    as well as unauthorized access, alteration, or destruction.
                  </p>
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='heading-10'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapse-10'
                  aria-expanded='false'
                  aria-controls='collapse-10'
                >
                  Our Commitment to Data Security
                </button>
              </h2>
              <div
                id='collapse-10'
                className='accordion-collapse collapse'
                aria-labelledby='heading-10'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    We adopt appropriate data security and data privacy
                    practices and procedures to protect the personal information
                    we collect.
                  </p>
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='heading-11'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapse-11'
                  aria-expanded='false'
                  aria-controls='collapse-11'
                >
                  CCPA Privacy Rights (Do Not Sell My Personal Information)
                </button>
              </h2>
              <div
                id='collapse-11'
                className='accordion-collapse collapse'
                aria-labelledby='heading-11'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    Under the CCPA, among other rights, California consumers
                    have the right to:
                    <br />
                    Request that a business that collects a consumer's personal
                    data disclose the categories and specific pieces of personal
                    data that a business has collected about consumers <br />
                    Request that a business that sells a consumer's personal
                    data, not sell the consumer's personal data <br />
                    Request that a business that sells a consumer's personal
                    data, delete the consumer's personal data from its files{' '}
                    <br />
                    Request that a business that sells a consumer's personal
                    data, terminate consumer access to its services <br />
                    Request to receive a copy of the personal data a business
                    has collected about the consumer <br />
                    Request to have a business that sells a consumer's personal
                    data, correct or delete the personal data <br />
                    Request to have a business that sells a consumer's personal
                    data, restrict, or erase personal data <br />
                    Request to have a business that sells a consumer's personal
                    data, define more specific opt-out rights or to exercise the
                    data protection rights <br />
                    Request to have a business that sells a consumer's personal
                    data, transfer the personal data to another business
                    Withdraw consent to receive marketing or sales materials
                    from a business that sells a consumer's personal data <br />
                  </p>
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='heading-12'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapse-12'
                  aria-expanded='false'
                  aria-controls='collapse-12'
                >
                  GDPR Data Protection Rights
                </button>
              </h2>
              <div
                id='collapse-12'
                className='accordion-collapse collapse'
                aria-labelledby='heading-12'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    We would like to make sure that we have all the necessary
                    rights to protect your personal data. We have implemented
                    the GDPR (General Data Protection Regulation) to provide you
                    with the following rights:
                    <br />
                    The right to access, update or delete your personal data.{' '}
                    <br />
                    The right to rectify or oppose to our processing of your
                    personal data. <br />
                    The right to object to our processing of your personal data.{' '}
                    <br />
                    The right to data portability. <br />
                    The right to withdraw consent. <br />
                  </p>
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='heading-12'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapse-12'
                  aria-expanded='false'
                  aria-controls='collapse-12'
                >
                  Payments
                </button>
              </h2>
              <div
                id='collapse-12'
                className='accordion-collapse collapse'
                aria-labelledby='heading-12'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    We may provide you with the opportunity to pay for your
                    order using a payment method. The payment method you use
                    will be stored by our payment processor and is not
                    accessible to us. We do not store your payment information.
                  </p>
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='heading-14'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapse-14'
                  aria-expanded='false'
                  aria-controls='collapse-14'
                >
                  Changes to This Privacy Policy
                </button>
              </h2>
              <div
                id='collapse-14'
                className='accordion-collapse collapse'
                aria-labelledby='heading-14'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    Dynamic may update this privacy policy from time to time. We
                    will notify you of any changes by posting the new privacy
                    policy on this page. You are advised to review this privacy
                    policy periodically for any changes. Changes to this privacy
                    policy are effective when they are posted on this page.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
