import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { SEO_PAGES } from '../../core/data';

function SEOHelmet(props) {
  const { pageKey } = props;

  const pageInfo = useMemo(() => {
    return Object.keys(SEO_PAGES).includes(pageKey)
      ? SEO_PAGES[pageKey]
      : SEO_PAGES['homepage'];
  }, [pageKey]);

  return (
    <Helmet>
      <title> Dynamic KSA - {pageInfo.title}</title>

      <meta name='description' content={pageInfo.description} />
    </Helmet>
  );
}

SEOHelmet.propTypes = {
  pageKey: PropTypes.oneOf(Object.keys(SEO_PAGES)).isRequired,
};

export default SEOHelmet;
